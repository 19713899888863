import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';
import { Selectable } from '~/lib/hooks/useSelectable';

import { TableRowData } from '../types';

/**
 * These change based on the following props changes:
 * `data`
 */
interface DataContext<Item extends TableRowData> {
  data: readonly Item[];
  onSelectAllRows: Selectable<Item>['onSelectAll'];
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const DataContext = createNamedContext<DataContext<any>>(
  'DataTable.DataContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const useDataTableData = <
  Item extends TableRowData,
>(): DataContext<Item> => {
  const value = useContext<DataContext<Item>>(DataContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      '`useDataTableData` must be used from within <DataTable />',
    );
  }

  return value;
};
