import clsx from 'clsx';
import { isFunction } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import {
  TableCell as UITableCell,
  tableCellClasses,
  TableCellProps as UITableCellProps,
} from '@gmm/ui';
import { usePrevious } from '~/lib/hooks/usePrevious';
import { getByPath, PropertyPath } from '~/lib/paths';

import { EitherColumn } from '../columns';
import { useDataTableColumns, useDataTableDetailPanels } from '../contextHooks';
import { TableRowData } from '../types';

interface Props<Item> extends UITableCellProps {
  column: EitherColumn<Item>;
  columnIndex: number;
  onCellClick?: (rowData: Item, rowIndex: number) => void;
  onToggleDetailPanel?: (
    rowData: Item,
    open: boolean,
    prop?: PropertyPath<Item>,
  ) => void | boolean;
  plainRowData?: Item;
  rowData: Item;
  rowIndex: number;
}

export const TableCell = <Item extends TableRowData>({
  className,
  column,
  columnIndex,
  onCellClick,
  onToggleDetailPanel,
  rowData,
  plainRowData = rowData,
  rowIndex,
  ...tableCellProps
}: Props<Item>): JSX.Element => {
  const {
    alwaysCollapseIfOpen = false,
    canRenderDetailPanel = false,
    cellProps,
    detailPanelProp,
    isIconOnly,
    name,
    prop,
    render,
  } = column;
  const {
    getOpenDetail,
    hideDetailPanel,
    isDetailPanelOpen,
    toggleDetailPanel,
  } = useDataTableDetailPanels<Item>();
  const panelProp = isFunction(detailPanelProp)
    ? detailPanelProp(rowData)
    : detailPanelProp;
  const cellData = getByPath(rowData, prop);
  const hasDetailPanel = useMemo(
    () =>
      typeof canRenderDetailPanel === 'function'
        ? canRenderDetailPanel(plainRowData)
        : canRenderDetailPanel,
    [canRenderDetailPanel, plainRowData],
  );
  const isDetailOpen = isDetailPanelOpen(rowData, panelProp);
  const wasDetailOpen = usePrevious(isDetailOpen);
  const { isFirstInGroup, numFrozenColumns } = useDataTableColumns<Item>();
  const openDetail = getOpenDetail(rowData);

  useEffect(() => {
    if (hasDetailPanel && panelProp && !isDetailOpen && wasDetailOpen) {
      hideDetailPanel(rowData, panelProp);
    }
  }, [
    hasDetailPanel,
    isDetailOpen,
    rowData,
    hideDetailPanel,
    panelProp,
    wasDetailOpen,
  ]);

  const handleCellClick = useCallback((): void => {
    const shouldCollapse = alwaysCollapseIfOpen && isDetailPanelOpen(rowData);

    if (shouldCollapse) {
      hideDetailPanel(rowData);
    } else if (hasDetailPanel && panelProp) {
      if (!onToggleDetailPanel?.(rowData, !isDetailOpen, prop)) {
        toggleDetailPanel(rowData, panelProp);
      }
    }

    onCellClick?.(rowData, rowIndex);
  }, [
    alwaysCollapseIfOpen,
    hasDetailPanel,
    hideDetailPanel,
    isDetailPanelOpen,
    isDetailOpen,
    onCellClick,
    onToggleDetailPanel,
    panelProp,
    prop,
    rowData,
    rowIndex,
    toggleDetailPanel,
  ]);

  const content = useMemo(
    () =>
      render
        ? render({
            cellData,
            isDetailOpen,
            openDetail,
            prop,
            rowData,
            rowIndex,
          })
        : cellData,
    [render, cellData, isDetailOpen, openDetail, prop, rowData, rowIndex],
  );

  const isClickable = !!onCellClick || (hasDetailPanel && !!panelProp);
  const frozen = columnIndex < numFrozenColumns;
  const hasBorder = isFirstInGroup(column);

  return (
    <UITableCell
      {...tableCellProps}
      {...cellProps}
      className={clsx(className, cellProps?.className)}
      data-testid={`${name || prop}`}
      onClick={handleCellClick}
      sx={{
        ...(isIconOnly
          ? {
              '& > button': {
                cursor: 'pointer',
                transition: theme =>
                  `${theme.transitions.create('transform', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.complex,
                  })}, ${theme.transitions.create('background-color', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.shortest,
                  })}`,
              },
            }
          : null),
        ...(hasBorder ? { borderLeft: 1, borderColor: 'grey.400' } : null),
        cursor: isClickable ? 'pointer' : 'default',
        position: 'relative',
        zIndex: 1,
        ...(frozen
          ? {
              bgcolor: 'inherit',
              left: 0,
              position: 'sticky',
              zIndex: 11,
              '::before': {
                bottom: 0,
                content: '""',
                bgcolor: 'grey.500',
                position: 'absolute',
                right: -1,
                top: 0,
                width: '1px',
              },
            }
          : null),
        [`&.${tableCellClasses.sizeSmall}`]: { px: 1 },
      }}
    >
      {content}
    </UITableCell>
  );
};
