import { useTranslation } from 'react-i18next';

import { Checkbox, CheckboxProps } from '~/lib/forms';

import { useDataTableRow, useDataTableSelectedRows } from './contextHooks';
import { TableRowData } from './types';

interface Props
  extends Omit<CheckboxProps, 'checked' | 'data-testid' | 'name' | 'value'> {
  'data-testid'?: string;
  name?: string;
  rowIndex: number;
}

export const SelectRowCheckbox = <Item extends TableRowData>({
  name = 'selected-rows',
  onChange,
  rowIndex,
  'data-testid': testId = name,
  ...checkboxProps
}: Props): JSX.Element => {
  const [t] = useTranslation(['table']);
  const getRow = useDataTableRow<Item>();
  const rowData = getRow(rowIndex);
  const { isRowSelected, onSelectRow } = useDataTableSelectedRows<Item>();
  const handleSelectChange: CheckboxProps['onChange'] = (event, checked) => {
    onSelectRow(rowData, checked);
    onChange?.(event, checked);
  };

  return (
    <Checkbox
      {...checkboxProps}
      checked={isRowSelected(rowData)}
      data-testid={testId}
      inputProps={{ 'aria-label': t('table:labels.selectRow', { rowIndex }) }}
      name={name}
      onChange={handleSelectChange}
    />
  );
};
