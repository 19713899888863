import { SyntheticEvent, useCallback } from 'react';

import { useLatestRef } from '@gmm/ui';

interface Options<T extends SyntheticEvent<unknown>> {
  primary?: (event: T, ...args: any) => unknown;
  secondary?: (event: T, ...args: any) => unknown;
  skipPreventDefaultCheck?: boolean;
}

/**
 * Used when you have multiple handlers that you need to merge together
 *
 * If `primary` `preventDefault()`s, the `secondary` will not be called unless
 * `skipPreventDefaultCheck` is set to `true`
 */
export const useMergeHandlers = <T extends SyntheticEvent<unknown>>({
  primary,
  secondary,
  skipPreventDefaultCheck,
}: Options<T>): ((event: T, ...args: any) => void) => {
  const primaryRef = useLatestRef(primary);
  const secondaryRef = useLatestRef(secondary);

  return useCallback<(event: T, ...args: any) => void>(
    (event, ...args) => {
      primaryRef.current?.(event, ...args);

      if (
        skipPreventDefaultCheck ||
        (typeof event.isDefaultPrevented === 'function' &&
          !event.isDefaultPrevented()) ||
        (typeof event.defaultPrevented === 'boolean' && !event.defaultPrevented)
      ) {
        secondaryRef.current?.(event, ...args);
      }
    },
    [primaryRef, secondaryRef, skipPreventDefaultCheck],
  );
};
