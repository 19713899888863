import axios from 'axios';
import { createDraft } from 'immer';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import * as apiSdk from '@gmm/sdk';
import {
  CurrentStatusIndexResponse as Response,
  CurrentStatusIndexItem as Item,
} from '@gmm/sdk/currentStatuses';
import {
  CurrentStatus,
  HandEventStatus,
  OverrideProblemEvent,
} from '@gmm/sdk/ws';
import { isTypedItem } from '@gmm/ui';
import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { ReadOnlyOptions } from '~/lib/sdk';
import { sortBy } from '~/lib/sortHelper';
import { ApiSdkSwrKey, swrKeyFor } from '~/lib/swr';

const isHandEventStatus = (status: unknown): status is HandEventStatus =>
  isTypedItem(status) &&
  (status.type === 'HandRaised' || status.type === 'HandLowered');

interface UseCurrentStatus {
  clearHelp: (studentInClassId: string) => Promise<void>;
  getStudentHelpOrder: (student: Item) => number;
  mutate: () => Promise<Response | undefined>;
  students: Response | undefined;
  swrKey: ApiSdkSwrKey<'currentStatuses.list'>;
  updateStudentProblem: (notification: OverrideProblemEvent) => Promise<void>;
  updateStudentStatus: (
    status: CurrentStatus | HandEventStatus,
  ) => Promise<void>;
}

export const useCurrentStatus = ({
  readOnly,
}: ReadOnlyOptions = {}): UseCurrentStatus => {
  const { classId } = useParams<{ classId: string }>();
  const { enqueueGenericError, enqueueSuccess } = useSnackbar();
  const [t] = useTranslation(['currentStatus']);
  const [t2] = useTranslation(['studentDetail']);
  const swrKey = swrKeyFor(['currentStatuses.list', classId]);
  const {
    data: students,
    mutate,
    mutateDraft,
  } = useSWR<Response>(swrKey, {
    onError: () => enqueueGenericError(),
    revalidateIfStale: !readOnly,
    revalidateOnReconnect: !readOnly,
  });

  const getStudentHelpOrder = (student: Item): number => {
    if (!students) return 0;
    const studentsWithHandsRaised = students.filter(
      student => student.help! > -1,
    );
    const sortedHelpStudents = sortBy<Item>('help')(studentsWithHandsRaised);

    return sortedHelpStudents.indexOf(student) + 1;
  };

  const updateStudentProblem = async (
    notification: OverrideProblemEvent,
  ): Promise<void> => {
    await mutateDraft(draft => {
      const student = draft.find(
        ({ studentInClassId }) =>
          studentInClassId === notification.studentInClassId,
      );

      if (!student) {
        return;
      }

      student.helpRequestedProblem = createDraft(
        notification.helpRequestedProblem,
      );

      if (notification.teacherTriggered)
        enqueueSuccess(t2('studentDetail:success.problemOverridden'));
    }, false);
  };

  const updateStudentStatus = async (
    status: CurrentStatus | HandEventStatus,
  ): Promise<void> => {
    if (isEmpty(status) || !status.studentInClassId) {
      return;
    }

    const wasHandRaisedOrLowered = isHandEventStatus(status);
    const wasHandLowered = wasHandRaisedOrLowered
      ? status.type === 'HandLowered'
      : false;
    const hasAssignment = 'exercise' in status;

    await mutateDraft(draft => {
      const student = draft.find(
        ({ studentInClassId }) => studentInClassId === status.studentInClassId,
      );

      if (student) {
        Object.assign(student, status);

        // Prevents missing potential exercise from not updating
        if (!hasAssignment && !wasHandRaisedOrLowered) {
          student.exercise = undefined;
        }

        if (wasHandLowered) delete student.helpRequestedProblem;
      }
    }, false);
  };

  const clearHelp = async (studentInClassId: string): Promise<void> => {
    try {
      await mutateDraft(draft => {
        const found = draft.find(
          student => student.studentInClassId === studentInClassId,
        );

        if (found) {
          delete found.help;
          delete found.helpRequestedProblem;
        }
      }, false);

      const config = apiSdk.helpRequests.destroy(studentInClassId);

      await axios.request(config);

      enqueueSuccess(t('currentStatus:clearHelpSuccess'));
    } catch {
      await mutate();
      enqueueGenericError();
    }
  };

  return {
    clearHelp,
    getStudentHelpOrder,
    mutate,
    students,
    swrKey,
    updateStudentProblem,
    updateStudentStatus,
  };
};
