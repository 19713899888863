import { useField } from 'formik';
import { get } from 'lodash';
import { FC, forwardRef, memo, ReactNode, useContext, useMemo } from 'react';
import { Namespace, useTranslation } from 'react-i18next';

import {
  Box,
  Chip,
  Divider,
  MenuItem,
  ListItemText,
  listItemTextClasses,
  Truncate,
  SxProps,
  sxToArray,
} from '@gmm/ui';
import { createNamedContext } from '~/lib/createNamedContext';
import { useMergeHandlers } from '~/lib/hooks/useMergeHandlers';
import { DistributedOmit, DistributedPick, WithLabelOrKey } from '~/lib/types';

import { useError } from './hooks/useError';
import { TextField, TextFieldProps } from './textField';

interface BaseDropdownOption {
  description?: string;
  disabled?: boolean;
  value: string | number | undefined;
  width?: number;
}

type DropdownOption = BaseDropdownOption & WithLabelOrKey;
export type DropdownOptions = readonly DropdownOption[];

type BaseProps = DistributedOmit<
  TextFieldProps,
  'label' | 'select' | 'type'
> & {
  actions?: DropdownOptions;
  multiple?: boolean;
  options: DropdownOptions;
  optionsListIntercomTarget?: string;
  value?: string | string[];
};
export type DropdownProps = BaseProps &
  WithLabelOrKey & { 'data-testid'?: string };

type DropdownOptionProps = DropdownOption & {
  className?: string;
  sx?: SxProps;
  testId?: string;
};

const DropdownOption = forwardRef<HTMLLIElement, DropdownOptionProps>(
  function DropdownOption(
    { description, label, labelI18nKey, sx, testId, width, ...props },
    ref,
  ) {
    const [t] = useTranslation<Namespace>();
    const formattedLabel = labelI18nKey ? t(labelI18nKey) : label;

    return (
      <MenuItem
        data-testid={`${testId}-value-${formattedLabel}`}
        ref={ref}
        sx={[{ width: width ?? 'auto' }, ...sxToArray(sx)]}
        {...props}
      >
        <ListItemText
          primary={
            <Truncate tooltipProps={{ placement: 'right' }}>
              {formattedLabel}
            </Truncate>
          }
          secondary={description}
          sx={{
            [`& .${listItemTextClasses.primary}`]: description
              ? { fontWeight: 500 }
              : {},
            [`& .${listItemTextClasses.secondary}`]: { whiteSpace: 'normal' },
          }}
        />
      </MenuItem>
    );
  },
);

export const Dropdown: FC<DropdownProps> = ({
  actions,
  className,
  'data-testid': testId,
  inputProps,
  label,
  labelI18nKey,
  multiple,
  options,
  optionsListIntercomTarget,
  SelectProps,
  value,
  ...textFieldProps
}) => {
  const [t] = useTranslation<Namespace>();
  const findOptionLabel = (selected: string | number): ReactNode => {
    const option = options.find(
      ({ value }) => String(value) === String(selected),
    );

    if (!option) return '';

    const { label, labelI18nKey } = option;

    return labelI18nKey ? t(labelI18nKey) : label;
  };

  return (
    <TextField
      {...textFieldProps}
      className={className}
      data-testid={`${testId}-root`}
      inputProps={{ ...inputProps, 'data-testid': `${testId}-input` }}
      label={labelI18nKey ? t(labelI18nKey) : label}
      select
      SelectProps={{
        ...SelectProps,
        MenuProps: {
          ...get(SelectProps, 'MenuProps'),
          PaperProps: {
            ...get(SelectProps, ['MenuProps', 'PaperProps']),
            'data-intercom-target': optionsListIntercomTarget,
          },
        },
        multiple,
        renderValue: selected => {
          if (typeof selected === 'string' || typeof selected === 'number') {
            return findOptionLabel(selected);
          }

          if (Array.isArray(selected)) {
            return (
              <Box
                sx={{ display: 'flex', flexWrap: 'wrap', '> *': { mx: 0.25 } }}
              >
                {selected.map(value => {
                  const label = findOptionLabel(value);

                  return <Chip key={value} label={label} />;
                })}
              </Box>
            );
          }

          return '';
        },
        SelectDisplayProps: { 'data-testid': testId },
      }}
      value={value || ''}
    >
      <MenuItem data-testid={`${testId}-disabled-value`} disabled value="" />
      {options.map(option => (
        <DropdownOption key={option.value} {...option} testId={testId} />
      ))}
      {actions?.length && <Divider />}
      {actions?.map(action => (
        <DropdownOption
          key={action.value}
          {...action}
          sx={{
            color: 'primary.main',
            textTransform: 'uppercase',
            typography: 'subtitle2',
          }}
          testId={testId}
        />
      ))}
    </TextField>
  );
};

type DropdownContext = DistributedPick<
  DropdownProps,
  'error' | 'helperText' | 'name' | 'onBlur' | 'onChange' | 'value'
>;

const DropdownContext = createNamedContext<DropdownContext>(
  'TextFieldContext',
  null as any,
);

type FormikDropdownProps = DistributedOmit<
  DropdownProps,
  keyof DropdownContext
>;

const FormikDropdown = memo<FormikDropdownProps>(function FormikDropdown(
  props,
) {
  const value = useContext(DropdownContext);

  return <Dropdown {...props} {...value} />;
});

type ConnectedDropdownProps = DropdownProps & { name: string };

export const ConnectedDropdown = memo<ConnectedDropdownProps>(
  function ConnectedDropdown({
    helperText = ' ',
    name,
    onBlur,
    onChange,
    value,
    ...props
  }) {
    const [field, meta] = useField(
      useMemo(
        () => ({
          as: 'select',
          multiple: props.multiple,
          name,
          value,
        }),
        [props.multiple, name, value],
      ),
    );
    const error = useError({ error: meta.error, touched: meta.touched });
    const handleBlur = useMergeHandlers({
      primary: onBlur,
      secondary: field.onBlur,
    });
    const handleChange = useMergeHandlers({
      primary: onChange,
      secondary: field.onChange,
    });

    const contextValue = useMemo<DropdownContext>(
      () => ({
        error: !!error,
        helperText: error || helperText,
        name: field.name,
        onBlur: handleBlur,
        onChange: handleChange,
        value: field.value || '',
      }),
      [field.name, field.value, error, helperText, handleBlur, handleChange],
    );

    return (
      <DropdownContext.Provider value={contextValue}>
        <FormikDropdown {...props} />
      </DropdownContext.Provider>
    );
  },
);
