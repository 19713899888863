import { FC, ReactElement } from 'react';

import { HelpOutline } from '@gmm/icons';
import { alpha, SvgIconProps, sxToArray, Tooltip } from '@gmm/ui';

interface Props extends SvgIconProps {
  tooltipTestId?: string;
  tooltipText: ReactElement | string;
}

export const HelpIconWithTooltip: FC<Props> = ({
  sx,
  tooltipTestId,
  tooltipText,
  ...iconProps
}) => {
  return (
    <Tooltip data-testid={tooltipTestId} placement="top" title={tooltipText}>
      <HelpOutline
        {...iconProps}
        sx={[
          {
            color: theme => alpha(theme.palette.common.black, 0.5),
            fontSize: '0.8em',
            ml: '3px',
          },
          ...sxToArray(sx),
        ]}
      />
    </Tooltip>
  );
};
