import { getIn, useFormikContext } from 'formik';
import produce, { original } from 'immer';
import { nanoid } from 'nanoid';
import { ReactNode, useContext, useMemo, useRef } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';
import { typedMemo } from '~/lib/typedMemo';

interface FieldArrayContext<T> {
  /** Mostly used to make sure a render happens */
  length: number;
  /** map the values */
  map: (
    callback: (id: string, index: number, array: string[]) => ReactNode,
  ) => ReactNode;
  name: string;
  push: (obj: T) => void;
  remove: (index: number) => T | undefined;
}

const FieldArrayContext = createNamedContext<FieldArrayContext<any>>(
  'FieldArrayContext',
  null as any,
);

type Props = {
  children: ReactNode;
  name: string;
};

export const FieldArray = typedMemo(function FieldArray<T>({
  children,
  name,
}: Props) {
  const { setFormikState, values } = useFormikContext();
  const slice: T[] = getIn(values, name, []);
  const valuesRef = useRef<string[]>(slice.map(() => nanoid()));
  const push = useRef((item: T): void => {
    const nextState = produce(valuesRef.current, draft => {
      draft.push(nanoid());
    });

    valuesRef.current = nextState;
    setFormikState(
      produce(draft => {
        getIn(draft.values, name, []).push(item);
      }),
    );
  });
  const remove = useRef((index: number): T | undefined => {
    let removedItem: T | undefined = undefined;

    valuesRef.current = produce(valuesRef.current, draft => {
      draft.splice(index, 1);
    });
    setFormikState(
      produce(draft => {
        const errors = getIn(draft.errors, name, []);
        const [removed] = getIn(draft.values, name, []).splice(index, 1);

        errors.splice(index, 1);
        getIn(draft.touched, name, []).splice(index, 1);
        removedItem = original(removed);

        if (errors.filter(Boolean).length === 0) {
          delete draft.errors[name as keyof typeof draft.errors];
        }
      }),
    );

    return removedItem;
  });
  const contextValue = useMemo<FieldArrayContext<T>>(
    () => ({
      length: slice.length,
      map: callback => valuesRef.current.map(callback),
      name,
      push: push.current,
      remove: remove.current,
    }),
    [slice.length, name],
  );

  return (
    <FieldArrayContext.Provider value={contextValue}>
      {children}
    </FieldArrayContext.Provider>
  );
});

export const useFieldArray = <T,>(): FieldArrayContext<T> => {
  const value = useContext<FieldArrayContext<T>>(FieldArrayContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      'useFieldArray should only be used within a FieldArray (not Formik’s version)',
    );
  }

  return value;
};
