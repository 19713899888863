import { iconButtonClasses } from '@mui/material';
import { margin } from 'polished';

import { IconButton, styled } from '@gmm/ui';

interface RootProps {
  rotate: boolean;
}

export const DetailToggle = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'rotate',
})<RootProps>(({ rotate }) => ({
  transform: `rotate(${rotate ? 90 : 0}deg)`,
  [`&.${iconButtonClasses.sizeSmall}`]: margin(-3, 0, -3, -3),
}));
