import { Draft, produce } from 'immer';
import { constant, noop, size } from 'lodash';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useHistory } from 'react-router-dom';

import { useLocationEffect } from '~/lib/hooks/useLocationEffect';
import { PropertyPath } from '~/lib/paths';

export type Details<T> = Array<[string, T]>;
export type DetailPanels<T> = {
  [key: string]: (props: { rowData: T; onClose: () => void }) => ReactNode;
};

interface Options<T> {
  allowMultiple?: boolean;
  detailPanels?: DetailPanels<T>;
  getIdentifier: (item: T) => T | PropertyPath<T>;
  initialDetails?: Details<T>;
  tableId: string;
}

const emptyDetails = constant([]);

export interface UseDetailPanels<T> {
  getOpenDetail: (item: T) => string | null;
  hideAllDetailPanels: (item: T[]) => void;
  hideDetailPanel: (item: T, prop?: string) => void;
  isDetailPanelOpen: (item: T, prop?: string) => boolean;
  renderDetail: (
    item: T,
    onClose?: (rowData: T, open: boolean) => void | boolean,
  ) => ReactNode;
  showDetailPanel: (item: T, prop: string) => void;
  toggleDetailPanel: (item: T, prop: string) => void;
}

const noopValue: UseDetailPanels<unknown> = {
  getOpenDetail: constant(null),
  hideAllDetailPanels: noop,
  hideDetailPanel: noop,
  isDetailPanelOpen: constant(false),
  renderDetail: constant(null),
  showDetailPanel: noop,
  toggleDetailPanel: noop,
};

type LocationState<T> = Record<string, Details<T | PropertyPath<T>>>;

export const useDetailPanels = <T>({
  allowMultiple = false,
  detailPanels = {},
  getIdentifier,
  initialDetails = [],
  tableId,
}: Options<T>): UseDetailPanels<T> => {
  const hasDetailPanels = size(detailPanels) > 0;
  const initialDetailsRef = useRef<Details<T | PropertyPath<T>>>(
    hasDetailPanels && initialDetails.length
      ? initialDetails
          .slice(0, allowMultiple ? initialDetails.length : 1)
          .map(([prop, item]) => [prop, getIdentifier(item)])
      : initialDetails,
  );
  const stateKey = useMemo(() => `${tableId}Details`, [tableId]);
  const history = useHistory<LocationState<T>>();
  const [details, setDetails] = useState<Details<T | PropertyPath<T>>>(
    initialDetailsRef.current,
  );
  const itemsAreEqual = useCallback(
    (detailItem: T | PropertyPath<T>, item: T): boolean =>
      isEqual(detailItem, getIdentifier(item)),
    [getIdentifier],
  );

  const findDetail = useCallback(
    (item: T, prop?: string) =>
      details.find(
        ([detailProp, detailItem]) =>
          (!prop || prop === detailProp) && itemsAreEqual(detailItem, item),
      ),
    [details, itemsAreEqual],
  );

  const getOpenDetail = useCallback(
    (item: T) => {
      const found = findDetail(item);

      return found ? found[0] : null;
    },
    [findDetail],
  );

  const isDetailPanelOpen = useCallback(
    (item: T, prop?: string) => !!findDetail(item, prop),
    [findDetail],
  );

  const addDetail = useCallback(
    (item: T, prop: string): Details<T | PropertyPath<T>> => {
      if (!allowMultiple) return [[prop, getIdentifier(item)]];

      return produce(details, (draft: Draft<Details<T>>) => {
        draft.push([prop, getIdentifier(item) as Draft<T>]);
      });
    },
    [allowMultiple, details, getIdentifier],
  );

  const removeDetail = useCallback(
    (item: T, prop?: string) => {
      if (!allowMultiple) {
        return emptyDetails();
      }

      return produce(details, (draft: Draft<Details<T>>) => {
        const index = draft.findIndex(
          ([detailProp, detailItem]) =>
            (!prop || prop === detailProp) &&
            itemsAreEqual(detailItem as T, item),
        );

        draft.splice(index, 1);
      });
    },
    [allowMultiple, details, itemsAreEqual],
  );

  const showDetailPanel = useCallback(
    (item: T, prop: string) => {
      if (!detailPanels[prop]) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error(
            `Can't show detail panel for unknown detail: ${prop}`,
          );
        }

        return;
      }

      history.replace(history.location.pathname, {
        ...history.location.state,
        [stateKey]: addDetail(item, prop),
      });
    },
    [addDetail, detailPanels, history, stateKey],
  );

  const hideDetailPanel = useCallback(
    (item: T, prop?: string) => {
      if (!isDetailPanelOpen(item, prop)) return;

      history.replace(history.location.pathname, {
        ...history.location.state,
        [stateKey]: removeDetail(item, prop),
      });
    },
    [history, isDetailPanelOpen, removeDetail, stateKey],
  );

  const hideAllDetailPanels = useCallback(
    (items: T[]) => {
      items.forEach(item => hideDetailPanel(item));
    },
    [hideDetailPanel],
  );

  const toggleDetailPanel = useCallback(
    (item: T, prop: string) => {
      if (isDetailPanelOpen(item, prop)) return hideDetailPanel(item, prop);

      showDetailPanel(item, prop);
    },
    [showDetailPanel, hideDetailPanel, isDetailPanelOpen],
  );

  const renderDetail = useCallback(
    (item: T, onClose?: (rowData: T, open: boolean) => void | boolean) => {
      const [prop] = findDetail(item) ?? [];

      if (!prop) return null;

      const handleClose = (): void => {
        onClose?.(item, false);
        hideDetailPanel(item);
      };

      return detailPanels[prop]({ rowData: item, onClose: handleClose });
    },
    [detailPanels, findDetail, hideDetailPanel],
  );

  useLocationEffect<LocationState<T>>(
    location => {
      if (!hasDetailPanels || !location.state) return;

      const detailState = location.state[stateKey];

      setDetails(currentDetails => {
        if (isEqual(detailState, currentDetails)) {
          return currentDetails;
        }

        return detailState || [];
      });
    },
    [hasDetailPanels, stateKey],
  );

  useEffect(() => {
    if (
      !initialDetailsRef.current.length &&
      !history.location.state?.[stateKey]?.length
    ) {
      return;
    }

    history.replace(history.location.pathname, {
      ...history.location.state,
      [stateKey]: initialDetailsRef.current,
    });
  }, [history, stateKey]);

  const value = useMemo(
    () => ({
      getOpenDetail,
      hideAllDetailPanels,
      hideDetailPanel,
      isDetailPanelOpen,
      renderDetail,
      showDetailPanel,
      toggleDetailPanel,
    }),
    [
      getOpenDetail,
      hideAllDetailPanels,
      hideDetailPanel,
      isDetailPanelOpen,
      renderDetail,
      showDetailPanel,
      toggleDetailPanel,
    ],
  );

  return hasDetailPanels ? value : (noopValue as UseDetailPanels<T>);
};
