import { useField } from 'formik';
import {
  FC,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { isElement } from 'react-is';

import {
  FormControlLabel,
  FormControlLabelProps,
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from '@gmm/ui';
import { createNamedContext } from '~/lib/createNamedContext';
import { useMergeHandlers } from '~/lib/hooks/useMergeHandlers';
import { DistributedOmit, DistributedPick } from '~/lib/types';

export interface CheckboxProps extends MUICheckboxProps {
  'data-testid': string;
  FormControlLabelProps?: Partial<FormControlLabelProps>;
  label?: ReactNode;
  name: string;
  value?: string | boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  FormControlLabelProps,
  'data-testid': testId,
  inputProps,
  label,
  ...props
}) => {
  const control = (
    <MUICheckbox
      id={testId}
      {...props}
      data-testid={testId}
      inputProps={
        {
          ...inputProps,
          'data-testid': `${testId}-input`,
        } as unknown as InputHTMLAttributes<HTMLInputElement>
      }
    />
  );

  if (
    isElement(label) ||
    typeof label === 'string' ||
    typeof label === 'number'
  ) {
    return (
      <FormControlLabel
        {...FormControlLabelProps}
        control={control}
        label={label}
      />
    );
  }

  return control;
};

type CheckboxContext = DistributedPick<
  CheckboxProps,
  'checked' | 'name' | 'onBlur' | 'onChange' | 'value'
>;

const CheckboxContext = createNamedContext<CheckboxContext>(
  'CheckboxContext',
  null as any,
);

type FormikCheckboxProps = DistributedOmit<
  CheckboxProps,
  keyof CheckboxContext
>;

const FormikCheckbox = memo<FormikCheckboxProps>(function FormikCheckbox(
  props,
) {
  const value = useContext(CheckboxContext);

  return <Checkbox {...props} {...value} />;
});

export const ConnectedCheckbox = memo<CheckboxProps>(
  function ConnectedCheckbox({ name, onBlur, onChange, value, ...props }) {
    const [field] = useField(
      useMemo(
        () => ({
          name,
          type: 'checkbox',
          value: value ? `${value}` : undefined,
        }),
        [name, value],
      ),
    );
    const handleBlur = useMergeHandlers({
      primary: onBlur,
      secondary: field.onBlur,
    });
    const handleChange = useMergeHandlers({
      primary: onChange,
      secondary: field.onChange,
    });

    const contextValue = useMemo<CheckboxContext>(
      () => ({
        checked: field.checked,
        name: field.name,
        onBlur: handleBlur,
        onChange: handleChange,
        value: field.value || '',
      }),
      [field.checked, field.name, field.value, handleBlur, handleChange],
    );

    return (
      <CheckboxContext.Provider value={contextValue}>
        <FormikCheckbox {...props} />
      </CheckboxContext.Provider>
    );
  },
);
