import { identity } from 'lodash';
import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';
import { PropertyPath } from '~/lib/paths';

import { TableRowData } from '../types';

/**
 * The change based on the following props changes: `identifier`
 */
type IdentifierContext<Item extends TableRowData> = (
  obj: Item,
) => Item | PropertyPath<Item>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IdentifierContext = createNamedContext<IdentifierContext<any>>(
  'DataTable.IdentifierContext',
  identity,
);

export const useIdentifier = <
  Item extends TableRowData,
>(): IdentifierContext<Item> =>
  useContext<IdentifierContext<Item>>(IdentifierContext);
