import { useField } from 'formik';
import { FC, memo, Ref, useContext, useMemo } from 'react';

import {
  TextField as GmmTextField,
  TextFieldProps as GmmTextFieldProps,
} from '@gmm/ui';
import { createNamedContext } from '~/lib/createNamedContext';
import { useMergeHandlers } from '~/lib/hooks/useMergeHandlers';
import { DistributedOmit, DistributedPick } from '~/lib/types';

import { useError } from './hooks';

export type TextFieldProps = Omit<GmmTextFieldProps, 'ref'> & {
  allowDoubleQuotes?: boolean;
  inputRef?: Ref<HTMLInputElement>;
};

export const TextField: FC<TextFieldProps> = ({
  allowDoubleQuotes,
  hiddenLabel,
  inputProps,
  inputRef,
  label,
  ...textFieldProps
}) => {
  const isTextType =
    textFieldProps.type !== 'search' && textFieldProps.type !== 'number';
  const inputPropsWithHiddenLabel =
    hiddenLabel && typeof label === 'string'
      ? {
          ...inputProps,
          'aria-label': label,
        }
      : inputProps;
  const finalInputProps =
    !allowDoubleQuotes && isTextType
      ? {
          pattern: '^[^"]*$',
          ...inputPropsWithHiddenLabel,
        }
      : inputPropsWithHiddenLabel;

  return (
    <GmmTextField
      {...textFieldProps}
      hiddenLabel={hiddenLabel}
      inputProps={finalInputProps}
      label={hiddenLabel ? null : label}
      ref={inputRef}
    />
  );
};

type TextFieldContext = DistributedPick<
  TextFieldProps,
  'name' | 'error' | 'helperText' | 'onBlur' | 'onChange' | 'type' | 'value'
>;

const TextFieldContext = createNamedContext<TextFieldContext>(
  'TextFieldContext',
  null as any,
);

type FormikTextFieldProps = DistributedOmit<
  TextFieldProps,
  keyof TextFieldContext
>;

const FormikTextField = memo<FormikTextFieldProps>(function FormikTextField(
  props,
) {
  const value = useContext(TextFieldContext);

  return <TextField {...props} {...value} />;
});

type ConnectedTextFieldProps = TextFieldProps & {
  name: string;
};

/**
 * Wraps a text field with it's own context that only updates for items we care
 * about. This is super useful for big forms, but shouldn't really cause
 * much harm in any form.
 */
export const ConnectedTextField = memo<ConnectedTextFieldProps>(
  function ConnectedTextField({
    helperText = ' ',
    name,
    onBlur,
    onChange,
    type,
    ...props
  }) {
    const [field, meta] = useField(
      useMemo(() => ({ name, type }), [name, type]),
    );
    const error = useError({ error: meta.error, touched: meta.touched });
    const handleBlur = useMergeHandlers({
      primary: onBlur,
      secondary: field.onBlur,
    });
    const handleChange = useMergeHandlers({
      primary: onChange,
      secondary: field.onChange,
    });

    const contextValue = useMemo<TextFieldContext>(
      () => ({
        name: field.name,
        error: !!error,
        helperText: error || helperText,
        onBlur: handleBlur,
        onChange: handleChange,
        type,
        value: field.value ?? '',
      }),
      [
        field.name,
        field.value,
        error,
        helperText,
        handleBlur,
        handleChange,
        type,
      ],
    );

    return (
      <TextFieldContext.Provider value={contextValue}>
        <FormikTextField {...props} />
      </TextFieldContext.Provider>
    );
  },
);
