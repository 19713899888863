import { FC } from 'react';

import { Grid } from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';
import {
  Columns,
  DataTable,
  defineColumn,
  Table,
  TableBody,
  TableHeader,
} from '~/lib/dataTable';

const studentProblemPreviewIds = allIds.problemPreview.students;
const examProblemPreviewIds = allIds.classes.examAnalysis.problemPreview;

interface Student {
  firstName: string;
  lastName: string;
}

interface Props {
  students: Student[];
}

type StudentColumns = Columns<Student>;

/* eslint-disable react/display-name */
const columns: StudentColumns = [
  defineColumn('firstName', {
    cellProps: { className: 'fs-block' },
    label: 'problemPreview:studentsTable.columns.name',
    render: ({ rowData }) => `${rowData.firstName} ${rowData.lastName}`,
    sortable: true,
  }),
];
/* eslint-enable react/display-name */

export const Students: FC<Props> = ({ students }) => (
  <Grid
    container
    data-testid={studentProblemPreviewIds.root}
    id={studentProblemPreviewIds.root}
  >
    <Grid item xs={12}>
      <DataTable
        columns={columns}
        data={students}
        initialSortBy="firstName"
        initialSortOrder="asc"
        tableId={examProblemPreviewIds.tableId}
      >
        <Table removeIntercomPadding>
          <TableHeader />
          <TableBody<Student>
            data-testid={studentProblemPreviewIds.table}
            zebraStripe
          />
        </Table>
      </DataTable>
    </Grid>
  </Grid>
);
