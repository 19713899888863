import { TFunction } from 'i18next';
import { mapValues } from 'lodash';
import { ReactNode } from 'react';
import { I18nKey, Namespace, useTranslation } from 'react-i18next';
import { isElement } from 'react-is';

const defaultTranslationKey = 'label.capitalized';

const rePath = /path/i;
const translateTranslatableOptions = ({
  options,
  t,
}: {
  options: Record<string, any>;
  t: TFunction;
}): Record<string, any> =>
  mapValues(options, (value, key) => {
    if (rePath.test(key) && value.substring(0, 2) !== '$t') {
      const possibleKey = `label.${value}`;
      const possibleKeyFromArray = possibleKey.replace(/\[\d+\]/g, '');

      return t([possibleKeyFromArray, possibleKey, defaultTranslationKey], {
        path: value,
      });
    }

    return value;
  });

interface ErrorAndTouched {
  error?: string | { key: I18nKey; options: Record<string, any> };
  touched: boolean;
}

export const useError = ({ error, touched }: ErrorAndTouched): ReactNode => {
  const [t] = useTranslation<Namespace>(['validation']);

  if (!error || !touched) return undefined;
  if (typeof error === 'string' || isElement(error)) return error;
  const { key, options: opts } = error;
  const options = translateTranslatableOptions({ options: opts, t });

  return t(key, options);
};
