/* istanbul ignore file */
import { FormikContextType, useFormikContext } from 'formik';
import { ReactNode, useContext, useMemo, useRef } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';

type Subscription<T> = {
  [P in keyof FormikContextType<T>]?: boolean;
};

interface Props<S> {
  children: ReactNode;
  readonly subscription: S;
}

const PartialFormikContext = createNamedContext<
  Partial<FormikContextType<any>>
>('PartialFormikContext', null as any);

/**
 * This will only allow the properties listed in `subscription` to update
 * anything using `usePartialFormikContext`
 */
export const PartialFormikProvider = <T, S extends Subscription<T>>({
  children,
  subscription,
}: Props<S>): JSX.Element => {
  const formik = useFormikContext<T>();
  const subRef = useRef(subscription);

  const values = useMemo(
    () => (subRef.current.values ? formik.values : undefined),
    [formik.values],
  );
  const errors = useMemo(
    () => (subRef.current.errors ? formik.errors : undefined),
    [formik.errors],
  );
  const touched = useMemo(
    () => (subRef.current.touched ? formik.touched : undefined),
    [formik.touched],
  );
  const status = useMemo(
    () => (subRef.current.status ? formik.status : undefined),
    [formik.status],
  );
  const isSubmitting = useMemo(
    () => (subRef.current.isSubmitting ? formik.isSubmitting : undefined),
    [formik.isSubmitting],
  );
  const isValidating = useMemo(
    () => (subRef.current.isValidating ? formik.isValidating : undefined),
    [formik.isValidating],
  );
  const submitCount = useMemo(
    () => (subRef.current.submitCount ? formik.submitCount : undefined),
    [formik.submitCount],
  );
  const initialValues = useMemo(
    () => (subRef.current.initialValues ? formik.initialValues : undefined),
    [formik.initialValues],
  );
  const initialErrors = useMemo(
    () => (subRef.current.initialErrors ? formik.initialErrors : undefined),
    [formik.initialErrors],
  );
  const initialTouched = useMemo(
    () => (subRef.current.initialTouched ? formik.initialTouched : undefined),
    [formik.initialTouched],
  );
  const initialStatus = useMemo(
    () => (subRef.current.initialStatus ? formik.initialStatus : undefined),
    [formik.initialStatus],
  );
  const handleBlur = useMemo(
    () => (subRef.current.handleBlur ? formik.handleBlur : undefined),
    [formik.handleBlur],
  );
  const handleChange = useMemo(
    () => (subRef.current.handleChange ? formik.handleChange : undefined),
    [formik.handleChange],
  );
  const handleReset = useMemo(
    () => (subRef.current.handleReset ? formik.handleReset : undefined),
    [formik.handleReset],
  );
  const handleSubmit = useMemo(
    () => (subRef.current.handleSubmit ? formik.handleSubmit : undefined),
    [formik.handleSubmit],
  );
  const resetForm = useMemo(
    () => (subRef.current.resetForm ? formik.resetForm : undefined),
    [formik.resetForm],
  );
  const setErrors = useMemo(
    () => (subRef.current.setErrors ? formik.setErrors : undefined),
    [formik.setErrors],
  );
  const setFormikState = useMemo(
    () => (subRef.current.setFormikState ? formik.setFormikState : undefined),
    [formik.setFormikState],
  );
  const setFieldTouched = useMemo(
    () => (subRef.current.setFieldTouched ? formik.setFieldTouched : undefined),
    [formik.setFieldTouched],
  );
  const setFieldValue = useMemo(
    () => (subRef.current.setFieldValue ? formik.setFieldValue : undefined),
    [formik.setFieldValue],
  );
  const setFieldError = useMemo(
    () => (subRef.current.setFieldError ? formik.setFieldError : undefined),
    [formik.setFieldError],
  );
  const setStatus = useMemo(
    () => (subRef.current.setStatus ? formik.setStatus : undefined),
    [formik.setStatus],
  );
  const setSubmitting = useMemo(
    () => (subRef.current.setSubmitting ? formik.setSubmitting : undefined),
    [formik.setSubmitting],
  );
  const setTouched = useMemo(
    () => (subRef.current.setTouched ? formik.setTouched : undefined),
    [formik.setTouched],
  );
  const setValues = useMemo(
    () => (subRef.current.setValues ? formik.setValues : undefined),
    [formik.setValues],
  );
  const submitForm = useMemo(
    () => (subRef.current.submitForm ? formik.submitForm : undefined),
    [formik.submitForm],
  );
  const validateForm = useMemo(
    () => (subRef.current.validateForm ? formik.validateForm : undefined),
    [formik.validateForm],
  );
  const validateField = useMemo(
    () => (subRef.current.validateField ? formik.validateField : undefined),
    [formik.validateField],
  );
  const isValid = useMemo(
    () => (subRef.current.isValid ? formik.isValid : undefined),
    [formik.isValid],
  );
  const dirty = useMemo(
    () => (subRef.current.dirty ? formik.dirty : undefined),
    [formik.dirty],
  );
  const unregisterField = useMemo(
    () => (subRef.current.unregisterField ? formik.unregisterField : undefined),
    [formik.unregisterField],
  );
  const registerField = useMemo(
    () => (subRef.current.registerField ? formik.registerField : undefined),
    [formik.registerField],
  );
  const getFieldProps = useMemo(
    () => (subRef.current.getFieldProps ? formik.getFieldProps : undefined),
    [formik.getFieldProps],
  );
  const getFieldMeta = useMemo(
    () => (subRef.current.getFieldMeta ? formik.getFieldMeta : undefined),
    [formik.getFieldMeta],
  );
  const getFieldHelpers = useMemo(
    () => (subRef.current.getFieldHelpers ? formik.getFieldHelpers : undefined),
    [formik.getFieldHelpers],
  );
  const validateOnBlur = useMemo(
    () => (subRef.current.validateOnBlur ? formik.validateOnBlur : undefined),
    [formik.validateOnBlur],
  );
  const validateOnChange = useMemo(
    () =>
      subRef.current.validateOnChange ? formik.validateOnChange : undefined,
    [formik.validateOnChange],
  );
  const validateOnMount = useMemo(
    () => (subRef.current.validateOnMount ? formik.validateOnMount : undefined),
    [formik.validateOnMount],
  );

  const contextValue = useMemo<Partial<FormikContextType<T>>>(
    () => ({
      values,
      errors,
      touched,
      status,
      isSubmitting,
      isValidating,
      submitCount,
      initialValues,
      initialErrors,
      initialTouched,
      initialStatus,
      handleBlur,
      handleChange,
      handleReset,
      handleSubmit,
      resetForm,
      setErrors,
      setFormikState,
      setFieldTouched,
      setFieldValue,
      setFieldError,
      setStatus,
      setSubmitting,
      setTouched,
      setValues,
      submitForm,
      validateForm,
      validateField,
      isValid,
      dirty,
      unregisterField,
      registerField,
      getFieldProps,
      getFieldMeta,
      getFieldHelpers,
      validateOnBlur,
      validateOnChange,
      validateOnMount,
    }),
    [
      values,
      errors,
      touched,
      status,
      isSubmitting,
      isValidating,
      submitCount,
      initialValues,
      initialErrors,
      initialTouched,
      initialStatus,
      handleBlur,
      handleChange,
      handleReset,
      handleSubmit,
      resetForm,
      setErrors,
      setFormikState,
      setFieldTouched,
      setFieldValue,
      setFieldError,
      setStatus,
      setSubmitting,
      setTouched,
      setValues,
      submitForm,
      validateForm,
      validateField,
      isValid,
      dirty,
      unregisterField,
      registerField,
      getFieldProps,
      getFieldMeta,
      getFieldHelpers,
      validateOnBlur,
      validateOnChange,
      validateOnMount,
    ],
  );

  return (
    <PartialFormikContext.Provider value={contextValue}>
      {children}
    </PartialFormikContext.Provider>
  );
};

export const usePartialFormikContext = <
  T,
  S extends keyof FormikContextType<T>,
>(): Pick<FormikContextType<T>, S> => {
  const fullContext = useFormikContext<T>();
  const partialContext =
    useContext<Partial<FormikContextType<T>>>(PartialFormikContext);

  if (partialContext === null) {
    return fullContext;
  }

  return partialContext as Pick<FormikContextType<T>, S>;
};
