import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HexagonSlice as CondensedSkill } from '@gmm/icons';
import { Avatar, Box, Chip } from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';

const ids = allIds.problemPreview;

export const CondensedChip: FC = () => {
  const [t] = useTranslation(['problemPreview']);

  return (
    <Box sx={{ textAlign: 'left', width: 1 }}>
      <Chip
        avatar={
          <Avatar>
            <CondensedSkill color="primary" />
          </Avatar>
        }
        data-testid={ids.chip}
        label={t('problemPreview:condensed')}
      />
    </Box>
  );
};
