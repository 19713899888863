import { noop } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewColumn } from '@gmm/icons';
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
  useMenu,
} from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';
import { Checkbox } from '~/lib/forms';

import { getColumnLabel } from '../columns';
import { useDataTableColumns } from '../contextHooks';
import { TableRowData } from '../types';

const ids = allIds.dataTable;

interface Props extends Omit<MenuProps, 'open'> {
  size?: IconButtonProps['size'];
}

export const ColumnMenu = memo<Props>(function ColumnMenu<
  Item extends TableRowData,
>({
  className,
  onClose = noop,
  size,
  ...menuProps
}: Props): JSX.Element | null {
  const [t] = useTranslation(['table']);
  const { handleMenuClick, menuProps: addlMenuProps } = useMenu({
    onClose,
  });
  const { isVisible, hideableColumns, toggleColumnVisibility } =
    useDataTableColumns<Item>();

  if (hideableColumns.length === 0) return null;

  return (
    <>
      <Tooltip
        disableFocusListener={true}
        title={t('table:columnMenu.columnVisibility')!}
      >
        <IconButton
          className={className}
          data-testid={ids.tableColumnVisibility.icon}
          id={ids.tableColumnVisibility.icon}
          onClick={handleMenuClick}
          size={size}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>
      <Menu
        {...menuProps}
        {...addlMenuProps}
        data-testid={ids.tableColumnVisibility.menu}
        id={ids.tableColumnVisibility.menu}
      >
        <MenuItem
          key="text"
          disabled
          sx={{
            fontSize: theme => theme.typography.pxToRem(12),
            fontWeight: 'fontWeightMedium',
            opacity: 1,
          }}
        >
          {t('table:columnMenu.addOrRemoveColumns')}
        </MenuItem>
        {hideableColumns.map((column, index) => {
          const { label, prop } = column;
          const formLabel = getColumnLabel({ label });
          const handleToggleColumn = (): void => toggleColumnVisibility(column);

          return (
            <MenuItem key={String(prop)}>
              <Checkbox
                checked={isVisible(column)}
                data-testid={ids.tableColumnVisibility.byIndex(index)}
                id={ids.tableColumnVisibility.byIndex(index)}
                label={formLabel}
                name="visible-columns"
                onClick={handleToggleColumn}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
});
