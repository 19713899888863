import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';

import { TableRowData } from '../types';
import { UseDetailPanels } from '../useDetailPanels';

/**
 * The change based on the following props changes:
 * `allowMultipleDetails`, `detailPanels`, `identifier`
 *
 * They will also change after `hideDetails` or
 * `toggleDetails` has been triggered
 */
export type DetailPanelsContext<Item> = UseDetailPanels<Item>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DetailPanelsContext = createNamedContext<DetailPanelsContext<any>>(
  'DataTable.DetailPanelsContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const useDataTableDetailPanels = <
  Item extends TableRowData,
>(): DetailPanelsContext<Item> => {
  const value = useContext<DetailPanelsContext<Item>>(DetailPanelsContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      '`useDataTableDetailPanels` must be used from within <DataTable />',
    );
  }

  return value;
};
