import {
  ChangeEvent,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ExamProblemsShowResponse } from '@gmm/sdk/examProblems';
import { createNamedContext } from '~/lib/createNamedContext';

import { commonEvents } from '../analytics/events';
import { useToggle } from '../hooks';
import { useLocationEffect } from '../hooks/useLocationEffect';
import { MobiusProblemAndAnswer } from '../problemPreview/hooks/utils';

import { Sibling } from './utils';

enum SkillPreviewTab {
  Preview = 'preview',
  Answer = 'answer',
  Students = 'students',
}

interface OpenFullscreenWith {
  studentProblemData: MobiusProblemAndAnswer | null;
  description?: string;
  siblingSkills: Sibling[];
  title?: ReactNode;
  type: string;
  // If this is present, we're previewing a student's exam problem
  studentInTestId?: string;
  examRestoreId?: string;
  // If this is present, we're previewing Current Status
  studentInClassId?: string;
  helpRequestedProblemData?: ExamProblemsShowResponse;
}

interface Context {
  currentIndex: number;
  studentProblemData: MobiusProblemAndAnswer | null;
  currentType: string;
  description?: string;
  handleTabChange: (
    event: ChangeEvent<unknown>,
    newTab: SkillPreviewTab,
  ) => void;
  isModalOpen: boolean;
  onClose: () => void;
  openFullscreenWith: (args: OpenFullscreenWith) => void;
  setOnFullscreenClose: (
    onFullscreenClose: (() => OnFullscreenClose) | undefined,
  ) => void;
  setTitle: Dispatch<SetStateAction<ReactNode | undefined>>;
  showNextSibling: () => void;
  showPreviousSibling: () => void;
  siblingSkills: Sibling[];
  tab: string;
  title: ReactNode | undefined;
  studentInTestId?: string;
  examRestoreId?: string;
  studentInClassId?: string;
  // supports 'Problem #32' for arrowing through exam problems
  problemTabText?: string;
  helpRequestedProblemData?: ExamProblemsShowResponse;
}

type OnFullscreenClose = (skillType: string) => void;

const FullscreenContext = createNamedContext<Context>(
  'FullscreenContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const FullscreenProvider: FC = ({ children }) => {
  const [siblingSkills, setSiblingSkills] = useState<Sibling[]>([]);
  const [currentType, setCurrentType] = useState('');
  const [description, setDescription] = useState<string | undefined>();
  const [title, setTitle] = useState<ReactNode | undefined>();
  const [studentInTestId, setStudentInTestId] = useState<string | undefined>();
  const [examRestoreId, setExamRestoreId] = useState<string | undefined>();
  const [helpRequestedProblemData, sethelpRequestedProblemData] = useState<
    ExamProblemsShowResponse | undefined
  >(undefined);
  const [studentInClassId, setStudentInClassId] = useState<
    string | undefined
  >();
  const [problemTabText, setProblemTabText] = useState<string | undefined>();
  const [isModalOpen, showModal, closeModal] = useToggle();
  const [tab, setTab] = useState(SkillPreviewTab.Preview);
  const onFullscreenCloseRef = useRef<
    ((skillType: string) => void) | undefined
  >();
  const setOnFullscreenClose = (
    onFullscreenClose: (() => OnFullscreenClose) | undefined,
  ): void => {
    onFullscreenCloseRef.current =
      typeof onFullscreenClose === 'function'
        ? onFullscreenClose()
        : onFullscreenClose;
  };

  const [studentProblemData, setCurrentProblemData] =
    useState<MobiusProblemAndAnswer | null>(null);

  const handleTabChange = (
    event: ChangeEvent<unknown>,
    newTab: SkillPreviewTab,
  ): void => {
    event.stopPropagation();
    commonEvents.previewSkill(currentType, newTab);
    setTab(newTab);
  };

  const openFullscreenWith = ({
    studentProblemData,
    description,
    siblingSkills,
    title,
    type,
    studentInTestId,
    examRestoreId,
    studentInClassId,
    helpRequestedProblemData,
  }: OpenFullscreenWith): void => {
    if (tab === SkillPreviewTab.Students) {
      setTab(SkillPreviewTab.Preview);
    }

    showModal();
    setCurrentProblemData(studentProblemData);
    setCurrentType(type);
    setDescription(description);
    setSiblingSkills(siblingSkills);
    setTitle(title);
    setStudentInTestId(studentInTestId);
    setExamRestoreId(examRestoreId);
    setStudentInClassId(studentInClassId);
    sethelpRequestedProblemData(helpRequestedProblemData);
  };

  // Skills can have a type, an id, or both, depending on context.
  // In skill discovery, type refers to the Tag type, not the Skill type.
  // If a skill has an id, it's an exam RestoreId.
  let currentIndex = -1;
  const exam = !!examRestoreId;
  const hasType = !!siblingSkills.length && !!Number(siblingSkills[0].type);

  if (exam) {
    currentIndex = siblingSkills.findIndex(skill => skill.id === examRestoreId);
  } else {
    currentIndex = hasType
      ? siblingSkills.findIndex(skill => skill.type === currentType)
      : siblingSkills.findIndex(skill => skill.id === currentType);
  }

  useEffect(() => {
    if (exam) {
      const index = siblingSkills.findIndex(
        skill => skill.id === examRestoreId,
      );

      setProblemTabText(`Problem #${index + 1}`);
    } else {
      setProblemTabText(undefined);
    }
  }, [exam, siblingSkills, examRestoreId]);

  const showNextSibling = (): void => {
    if (currentIndex === -1) return;

    if (currentIndex === siblingSkills.length - 1) return;

    const next = siblingSkills[currentIndex + 1];

    setDescription(next.description || next.name);
    setCurrentType(hasType ? next.type! : next.id!);
    if (exam) setExamRestoreId(next.id);
    setTab(SkillPreviewTab.Preview);
  };

  const showPreviousSibling = (): void => {
    if (currentIndex === -1) return;

    if (currentIndex === 0) return;

    const next = siblingSkills[currentIndex - 1];

    setDescription(next.description || next.name);
    setCurrentType(hasType ? next.type! : next.id!);
    if (exam) setExamRestoreId(next.id);
    setTab(SkillPreviewTab.Preview);
  };

  const onClose = useCallback((): void => {
    closeModal();
    onFullscreenCloseRef.current?.(currentType);
    setOnFullscreenClose(undefined);
  }, [closeModal, currentType]);

  useLocationEffect(() => {
    setTab(SkillPreviewTab.Preview);
    setOnFullscreenClose(undefined);
  });

  useLocationEffect((_, action) => {
    if (action === 'POP') {
      onClose();
    }
  });

  const value = {
    currentIndex,
    currentType,
    description,
    handleTabChange,
    studentProblemData,
    isModalOpen,
    openFullscreenWith,
    onClose,
    setOnFullscreenClose,
    setTitle,
    showNextSibling,
    showPreviousSibling,
    siblingSkills,
    tab,
    title,
    studentInTestId,
    examRestoreId,
    studentInClassId,
    problemTabText,
    helpRequestedProblemData,
  };

  return (
    <FullscreenContext.Provider value={value}>
      {children}
    </FullscreenContext.Provider>
  );
};

export const useFullscreenContext = (): Context =>
  useContext(FullscreenContext);
