import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Button, Tooltip } from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';
import { useExercise } from '~/lib/hooks';

const ids = allIds.problemPreview;

interface Props {
  disabled?: boolean;
  isLoading: boolean;
  onAdd: () => void;
}

export const AddSkill: FC<Props> = ({ disabled, isLoading, onAdd }) => {
  const [t] = useTranslation(['problemPreview']);
  const match = useRouteMatch('/work');
  const { data: exercise } = useExercise({ readOnly: true });
  const workType = exercise?.type ?? 'Assignment';

  const [addButtonDisabled, setAddButtonsDisabled] = useState(isLoading);
  const delayDisableTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(delayDisableTimer.current!);

    if (!isLoading) return setAddButtonsDisabled(false);

    delayDisableTimer.current = setTimeout(
      () => setAddButtonsDisabled(true),
      1000,
    );

    return () => clearTimeout(delayDisableTimer.current!);
  }, [isLoading]);

  const getAddSkillTooltipText = (): string => {
    if (isLoading) return '';

    if (
      disabled &&
      (workType === 'Assignment' || workType === 'ExamTemplate')
    ) {
      return t(`problemPreview:addSkillDisabled_${workType}`);
    }

    if (match?.isExact) {
      return t('problemPreview:addSkillWillCreate');
    }

    return '';
  };

  return (
    <Tooltip
      data-testid={ids.addDisabled}
      placement="top"
      title={getAddSkillTooltipText()}
    >
      <div>
        <Button
          color="secondary"
          data-intercom-target="add-skill-to-practice-set"
          data-testid={ids.add}
          disabled={disabled || addButtonDisabled}
          onClick={onAdd}
          variant="contained"
        >
          {t('problemPreview:addSkill')}
        </Button>
      </div>
    </Tooltip>
  );
};
