/* eslint-disable @typescript-eslint/no-explicit-any */
import { result } from 'lodash';
import { orderBy } from 'natural-orderby';
import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';

import { useMountedRef, useDisplayNameState } from '@gmm/ui';
import { useSelectable } from '~/lib/hooks/useSelectable';
import { getByPath, pathsAreEqual, PropertyPath } from '~/lib/paths';
import { SortOrder } from '~/lib/sortHelper';

import { usePrevious } from '../hooks/usePrevious';

import { Columns, EitherColumn, SortReturnValue, useColumns } from './columns';
import {
  ColumnContext,
  DataContext,
  GetSortedDataContext,
  IdentifierContext,
  SelectedRowsContext,
  SortedDataContext,
  SortingContext,
  TableIdContext,
} from './contextHooks';
import { DetailPanelsContext } from './contextHooks/useDataTableDetailPanels';
import { createNameSort } from './displayNameLabel';
import { TableRowData } from './types';
import { DetailPanels, Details, useDetailPanels } from './useDetailPanels';
import { useSortColumns } from './useSortColumns';
import { useToggleColumns } from './useToggleColumns';

export interface DataTableProps<Item extends TableRowData> {
  /** Whether or not more than one detail panel can be open at a time */
  allowMultipleDetails?: boolean;
  children: ReactNode;
  columns: Columns<Item>;
  data: readonly Item[];
  detailPanels?: DetailPanels<Item>;
  forceSort?: SortReturnValue<Item> | false;
  initialDetails?: Details<Item>;
  initialHiddenColumns?: ReadonlyArray<PropertyPath<Item>>;
  initialSortBy?: PropertyPath<Item>;
  initialSortOrder?: SortOrder;
  numFrozenColumns?: number;
  onSelectionChange?: (items: Item[]) => void;
  onSort?: (columnPath: PropertyPath<Item>, sortOrder: SortOrder) => void;
  onToggleColumnVisibility?: (
    columnPath: PropertyPath<Item>,
    on: boolean,
  ) => void;
  /** unique identifier on each item in `data` */
  rowDataId?: PropertyPath<Item>;
  tableId: string;
}

export const makeSortFn = <Item,>(
  isDisplayNameFirstLast: boolean,
  visibleColumns: ReadonlyArray<EitherColumn<Item>>,
): ((sortBy: PropertyPath<Item> | undefined) => SortReturnValue<Item> | []) => {
  const nameSort = createNameSort(isDisplayNameFirstLast);

  return sortBy => {
    const column = visibleColumns.find(({ prop }) =>
      pathsAreEqual(prop, sortBy),
    );

    if (!column || !result(column, 'sortable')) return [];

    if (column.isDisplayName) {
      const [nameIdentifiers, nameOrders] = nameSort('asc');

      return (column.sort?.(nameIdentifiers, nameOrders) || [
        nameIdentifiers,
        nameOrders,
      ]) as SortReturnValue<Item>;
    }

    return result(column, 'sort') ?? [];
  };
};

export const getSort = <Item,>(
  sortBy: PropertyPath<Item> | undefined,
  isDisplayNameFirstLast: boolean,
  visibleColumns: ReadonlyArray<EitherColumn<Item>>,
): SortReturnValue<Item> | [] =>
  makeSortFn(isDisplayNameFirstLast, visibleColumns)(sortBy);

export const sortCollection = <Item,>(
  collection: Item[],
  sortOrder: SortOrder,
  ...[sortIdentifiers, sortOrders]: SortReturnValue<Item>
): Item[] => {
  const ordered = orderBy([...collection], sortIdentifiers, sortOrders);

  if (sortOrder === 'desc') {
    return ordered.reverse();
  }

  return ordered;
};

const sortInitialCollection = <Item,>(
  collection: readonly Item[],
  sortBy: PropertyPath<Item> | undefined,
  sortOrder: SortOrder | undefined,
  isDisplayNameFirstLast: boolean,
  visibleColumns: ReadonlyArray<EitherColumn<Item>>,
): readonly Item[] => {
  const sortArgs = getSort(sortBy, isDisplayNameFirstLast, visibleColumns);

  if (sortArgs.length === 0) return collection;

  return sortCollection([...collection], sortOrder ?? 'asc', ...sortArgs);
};

export const DataTable = <Item extends TableRowData>({
  allowMultipleDetails = false,
  children,
  columns,
  data,
  detailPanels,
  forceSort,
  initialDetails,
  initialHiddenColumns,
  initialSortBy,
  initialSortOrder = 'asc',
  numFrozenColumns = 0,
  onSelectionChange,
  onSort: onSortProp,
  onToggleColumnVisibility: onToggleColumnProp,
  rowDataId,
  tableId,
}: DataTableProps<Item>): JSX.Element => {
  const { isDisplayNameFirstLast } = useDisplayNameState();
  const wasDisplayNameFirstLast = usePrevious(isDisplayNameFirstLast);

  const allColumns = useColumns(columns);
  const groupColumns = useMemo(() => {
    if (!allColumns.some(column => !!column.columns)) return null;

    return allColumns;
  }, [allColumns]);
  const isFirstInGroup = useCallback(
    (col: EitherColumn<Item>): boolean =>
      !!groupColumns
        ?.slice(1)
        .some(column => column === col || column.columns?.[0] === col),
    [groupColumns],
  );
  const realColumns = useMemo(() => {
    if (groupColumns === null) return allColumns;

    return allColumns.flatMap(column => column.columns || column);
  }, [allColumns, groupColumns]);
  const {
    hiddenColumns,
    isHidden,
    isVisible,
    hideableColumns,
    toggleColumnVisibility,
    visibleColumns,
  } = useToggleColumns(
    useMemo(() => realColumns.filter(col => !col.inactive), [realColumns]),
    initialHiddenColumns,
  );

  // These props should not change, but ignore them if they do
  const initialSortByRef = useRef(initialSortBy);
  const initialSortOrderRef = useRef(initialSortOrder);

  const prevDataRef = useRef<readonly Item[]>([]);
  const plainData = useMemo(() => {
    const prevData = prevDataRef.current;
    const dataChanged =
      prevData.length !== data.length ||
      wasDisplayNameFirstLast !== isDisplayNameFirstLast ||
      data.some(datum => !prevData.find(d => isEqual(d, datum)));

    if (!dataChanged) return prevData;

    const initiallySortedData = sortInitialCollection(
      data,
      initialSortByRef.current,
      initialSortOrderRef.current,
      isDisplayNameFirstLast,
      visibleColumns,
    );

    prevDataRef.current = initiallySortedData;

    return initiallySortedData;
  }, [data, isDisplayNameFirstLast, visibleColumns, wasDisplayNameFirstLast]);

  const getIdentifier = useCallback(
    (item: Item) => (rowDataId ? getByPath(item, rowDataId) : item),
    [rowDataId],
  );
  const detailPanelContext = useDetailPanels({
    allowMultiple: allowMultipleDetails,
    detailPanels,
    getIdentifier,
    initialDetails,
    tableId,
  });
  const [sortedData, setSortedData] = useState(plainData);
  const { sortBy, sortOrder, onSort } = useSortColumns(
    initialSortByRef.current,
    initialSortOrderRef.current,
    tableId,
    onSortProp,
  );
  const { isSelected, onSelect, onSelectAll, selectedItems } = useSelectable({
    data: plainData,
    onChange: onSelectionChange,
  });

  const handleToggleColumn = useCallback(
    (column: EitherColumn<Item>): void => {
      const wasHidden = isHidden(column);

      toggleColumnVisibility(column);
      onToggleColumnProp?.(column.prop, wasHidden);
    },
    [isHidden, toggleColumnVisibility, onToggleColumnProp],
  );

  const getSort = useMemo(
    () => makeSortFn(isDisplayNameFirstLast, visibleColumns),
    [isDisplayNameFirstLast, visibleColumns],
  );

  const isMountedRef = useMountedRef();
  // Used to let us separate the sort updates from any data updates
  const [sortArgs, setSortArgs] = useState<SortReturnValue<Item> | []>([]);
  const prevSortArgs = usePrevious(sortArgs);
  const [isSorting, setIsSorting] = useState(false);

  // We're using `useLayoutEffect` to make sure that when the sort indicator
  // flips, it aligns with the data actually changing, rather than happening
  // prior to that (if the sort takes a while)
  useLayoutEffect(() => {
    setIsSorting(true);
    setSortArgs(forceSort || getSort(sortBy));
  }, [forceSort, getSort, sortBy]);

  useLayoutEffect(() => {
    if (!sortArgs.length) return setSortedData(plainData);

    setSortedData(sortCollection([...plainData], sortOrder, ...sortArgs));
    window.requestAnimationFrame(() => {
      if (isMountedRef.current) setIsSorting(false);
    });
  }, [isMountedRef, plainData, prevSortArgs, sortArgs, sortOrder]);

  // context values
  const columnContextValue = useMemo(
    () => ({
      groupColumns,
      hiddenColumns,
      hideableColumns,
      isFirstInGroup,
      isVisible,
      numFrozenColumns,
      toggleColumnVisibility: handleToggleColumn,
      visibleColumnCount: visibleColumns.length,
      visibleColumns,
    }),
    [
      numFrozenColumns,
      groupColumns,
      hiddenColumns,
      hideableColumns,
      handleToggleColumn,
      isFirstInGroup,
      isVisible,
      visibleColumns,
    ],
  );

  const dataContextValue = useMemo(
    () => ({
      data,
      onSelectAllRows: onSelectAll,
    }),
    [data, onSelectAll],
  );

  const selectedRowsContext = useMemo(
    () => ({
      isRowSelected: isSelected,
      onSelectRow: onSelect,
      selectedRows: selectedItems,
    }),
    [isSelected, onSelect, selectedItems],
  );

  const getSortedData = useCallback(
    (sortedRowIndex: number) => sortedData[sortedRowIndex],
    [sortedData],
  );

  const sortingContextValue = useMemo(
    () => ({
      isSorting,
      onSort,
      sortBy,
      sortOrder,
    }),
    [isSorting, onSort, sortBy, sortOrder],
  );

  return (
    <ColumnContext.Provider value={columnContextValue}>
      <DataContext.Provider value={dataContextValue}>
        <SelectedRowsContext.Provider value={selectedRowsContext}>
          <SortedDataContext.Provider value={sortedData}>
            <GetSortedDataContext.Provider value={getSortedData}>
              <SortingContext.Provider value={sortingContextValue}>
                <IdentifierContext.Provider value={getIdentifier}>
                  <DetailPanelsContext.Provider value={detailPanelContext}>
                    <TableIdContext.Provider value={tableId}>
                      {children}
                    </TableIdContext.Provider>
                  </DetailPanelsContext.Provider>
                </IdentifierContext.Provider>
              </SortingContext.Provider>
            </GetSortedDataContext.Provider>
          </SortedDataContext.Provider>
        </SelectedRowsContext.Provider>
      </DataContext.Provider>
    </ColumnContext.Provider>
  );
};
