import { noop } from 'lodash';
import { useCallback } from 'react';

import { useStorage, useLatestRef } from '@gmm/ui';
import { PropertyPath } from '~/lib/paths';
import { SortOrder, toggleSortOrder } from '~/lib/sortHelper';

type OnSort<Item> = (
  columnPath: PropertyPath<Item>,
  sortOrder: SortOrder,
) => void;

export interface UseSortColumns<
  Item,
  Path extends PropertyPath<Item> = PropertyPath<Item>,
> {
  onSort: (columnName: Path) => void;
  sortBy: undefined | Path;
  sortOrder: SortOrder;
}

export const useSortColumns = <Item extends Record<string, any>>(
  defaultSortBy: undefined | PropertyPath<Item>,
  defaultSortOrder: SortOrder,
  tableId: string,
  callback: OnSort<Item> = noop,
): UseSortColumns<Item> => {
  const callbackRef = useLatestRef(callback);

  const [sort, setSort] = useStorage(`${tableId}:sort`, {
    by: defaultSortBy,
    order: defaultSortOrder,
  });

  const onSort = useCallback(
    (columnPath: PropertyPath<Item>): void => {
      setSort(currentSort => {
        const { sortBy, sortOrder } = toggleSortOrder<PropertyPath<Item>>(
          currentSort.order,
          columnPath,
          currentSort.by,
        );

        if (sortBy === currentSort.by && sortOrder === currentSort.order) {
          // If nothing changed (which should be impossible), return the same reference
          return currentSort;
        }

        callbackRef.current(sortBy, sortOrder);

        return { by: sortBy, order: sortOrder };
      });
    },
    [callbackRef, setSort],
  );

  return {
    sortBy: sort.by,
    sortOrder: sort.order,
    onSort,
  };
};
