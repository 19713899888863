import { uniq } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';

import { useLatestRef } from '@gmm/ui';

import { usePrevious } from './usePrevious';

interface Options<T> {
  data: readonly T[];
  defaultSelected?: readonly T[];
  onChange?: (selected: T[]) => void;
}

export interface Selectable<T> {
  isSelected: (item: T) => boolean;
  onSelect: (item: T, checked: boolean) => void;
  onSelectAll: () => void;
  selectedItems: readonly T[];
}

type NonZeroValue<T> = Exclude<T, null | undefined | false | 0>;

const exists = <T>(value: T): value is NonZeroValue<T> => !!value;

export const useSelectable = <T>({
  data,
  defaultSelected = [],
  onChange,
}: Options<T>): Selectable<T> => {
  const [selected, setSelected] = useState<readonly T[]>(defaultSelected);
  const handleChange = useLatestRef(onChange);
  const previousData = usePrevious(data);

  const { current: onSelect } = useRef((item: T, checked: boolean) =>
    setSelected(currentSelected => {
      const nextItems = checked
        ? uniq([...currentSelected, item])
        : currentSelected.filter(selectedItem => !isEqual(selectedItem, item));

      handleChange.current?.(nextItems);

      return nextItems;
    }),
  );

  const onSelectAll = useCallback(
    () =>
      setSelected(currentSelected => {
        const nextItems = currentSelected.length > 0 ? [] : [...data];

        handleChange.current?.(nextItems);

        return nextItems;
      }),
    [data, handleChange],
  );

  const isSelected = useCallback(
    (item: T) => selected.includes(item),
    [selected],
  );

  useEffect(() => {
    setSelected(currentSelected => {
      if (!currentSelected.length || isEqual(data, previousData)) {
        return currentSelected;
      }

      return currentSelected
        .map(selectedItem => {
          if (data.length === previousData?.length) {
            const previousIndex = previousData.findIndex(item =>
              isEqual(selectedItem, item),
            );

            return data[previousIndex];
          }

          return data.find(item => isEqual(selectedItem, item));
        })
        .filter(exists);
    });
  }, [data, previousData]);

  return { isSelected, onSelect, onSelectAll, selectedItems: selected };
};
