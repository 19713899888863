import { lowerCase } from 'lodash';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { MoreVert } from '@gmm/icons';
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useDisplayNameState,
  useMenu,
} from '@gmm/ui';
import { commonEvents } from '~/lib/analytics/events';
import { ids as allIds } from '~/lib/constants';
import { DisplayNameFormat } from '~/lib/types';

const ids = allIds.dataTable.nameFormatMenu;

export const NameFormatMenu: FC = () => {
  const [t] = useTranslation(['app']);
  const match = useRouteMatch<{ path: string }>(
    '/classes/:classId/:path/(.*)?',
  );
  const [displayNameFormat, setDisplayNameFormat] = useDisplayNameState();
  const { handleMenuClick, handleMenuClose, menuProps } = useMenu();
  const makeHandleNameFormat =
    (format: DisplayNameFormat): MouseEventHandler =>
    event => {
      event.stopPropagation();
      match && commonEvents.setNameFormat(format, lowerCase(match.params.path));
      setDisplayNameFormat(format);
      handleMenuClose();
    };

  return (
    <>
      <Tooltip
        placement="left"
        title={t('app:statusTable.displayOptions')!}
        enterDelay={500}
      >
        <IconButton
          data-testid={ids.menuButton}
          id={ids.menuButton}
          onClick={handleMenuClick}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        {...menuProps}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        data-testid={ids.menu}
        id={ids.menu}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {displayNameFormat === DisplayNameFormat.LastFirst && (
          <MenuItem
            data-testid={ids.firstLast}
            id={ids.firstLast}
            onClick={makeHandleNameFormat(DisplayNameFormat.FirstLast)}
          >
            {t('app:statusTable.firstLast')}
          </MenuItem>
        )}
        {displayNameFormat === DisplayNameFormat.FirstLast && (
          <MenuItem
            data-testid={ids.lastFirst}
            id={ids.lastFirst}
            onClick={makeHandleNameFormat(DisplayNameFormat.LastFirst)}
          >
            {t('app:statusTable.lastFirst')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
