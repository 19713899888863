import { styled, TableSortLabel } from '@mui/material';
import { ComponentProps, forwardRef, memo, useContext } from 'react';

import { DataGridLevel2Context } from '../data-grid';

interface RootProps {
  align?: 'center' | 'left' | 'right';
  padding?: 'none';
}

const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'align' && prop !== 'padding',
})<RootProps>(({ align, padding, theme }) => ({
  alignItems: 'center',
  background: 'inherit',
  borderBottom: 'inherit',
  display: 'flex',
  flexDirection: align === 'right' ? 'row-reverse' : 'row',
  justifyContent: align === 'center' ? 'center' : 'normal',
  padding: padding === 'none' ? theme.spacing(0, 1) : theme.spacing(1),
  position: 'relative',
  textAlign: align,
}));

interface DataGridCellProps extends ComponentProps<typeof Root> {
  canSort?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
}

export const DataGridCell = memo(
  forwardRef<HTMLDivElement, DataGridCellProps>(function DataGridCell(
    { canSort, children, isSorted, isSortedDesc, onClick, ...rootProps },
    ref,
  ) {
    const gridLevel2 = useContext(DataGridLevel2Context);
    const isHeadCell = gridLevel2?.variant === 'head';
    const canReallySort = isHeadCell && canSort && onClick;

    return (
      <Root {...rootProps} onClick={onClick} ref={ref}>
        {canReallySort ? (
          <TableSortLabel
            active={isSorted}
            direction={isSortedDesc ? 'desc' : 'asc'}
          >
            {children}
          </TableSortLabel>
        ) : (
          children
        )}
      </Root>
    );
  }),
);

DataGridCell.displayName = 'DataGridCell';
