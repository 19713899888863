import { cloneDeep } from 'lodash';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Close, Fullscreen, Refresh } from '@gmm/icons';
import {
  buildProblemCanvasOwner,
  makeProblemContext,
  ProblemCanvas,
  ProblemContext,
  StaticProblemCanvas,
} from '@gmm/problem';
import { ExamProblemsShowResponse } from '@gmm/sdk/examProblems';
import {
  AppBar,
  Box,
  IconButton,
  Paper,
  Tab,
  tabClasses,
  Tabs,
  Toolbar,
  Typography,
} from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';
import { useFullscreenContext, Sibling } from '~/lib/fullscreenPreview';

import { commonEvents } from '../analytics/events';
import { useCurrentTeacher } from '../hooks/useCurrentTeacher';
import { useFeatureFlag } from '../launchDarkly';

import {
  AddSkill,
  CondensedChip,
  ProblemPreviewTabPanel,
  Students,
} from './components';
import { PreviewProps } from './hooks/utils';
import { PreviewType } from './types';

const ids = allIds.problemPreview;

enum SkillPreviewTab {
  Preview = 'preview',
  Answer = 'answer',
  Students = 'students',
}

interface MissedStudent {
  firstName: string;
  lastName: string;
}

interface Props extends Omit<PreviewProps, 'mutateDraft'> {
  description?: string;
  elevation?: number;
  isAddButtonDisabled?: boolean;
  isPreviewModal?: boolean;
  missedStudents?: MissedStudent[];
  mutateDraft?: PreviewProps['mutateDraft'];
  name?: string;
  onAdd?: () => void;
  onClose?: () => void;
  onHeightChange?: () => void;
  previewType?: PreviewType;
  readOnly?: boolean;
  showRefresh?: boolean;
  siblingSkills?: Sibling[];
  // If this is present, we're previewing Current Status
  studentInClassId?: string;
  title?: ReactNode;
  type?: string;
  // If this is present, we're previewing a student's exam problem
  studentInTestId?: string;
  examRestoreId?: string;
  // Specialty text, such as 'Problem #32' for arrowing through exam problems
  problemTabText?: string;
  helpRequestedProblemData?: ExamProblemsShowResponse;
}

export const ProblemPreview: FC<Props> = ({
  description,
  elevation,
  error,
  isAddButtonDisabled,
  isPreviewModal = false,
  isValidating,
  missedStudents,
  mutate: refresh,
  onAdd,
  onClose,
  onHeightChange,
  previewType = PreviewType.Sample,
  problemData = null,
  showRefresh,
  siblingSkills,
  studentInClassId,
  title,
  type = 'skill preview',
  studentInTestId,
  examRestoreId,
  problemTabText,
  helpRequestedProblemData,
}) => {
  const [t] = useTranslation(['problemPreview']);
  const fullscreenFlag = useFeatureFlag('Feature.TeacherTool', false);
  const { handleTabChange, openFullscreenWith, tab } = useFullscreenContext();
  const { data: teacher } = useCurrentTeacher();

  const problemCanvasOwner = useMemo(() => {
    const partialProblemContext: Partial<ProblemContext> = {
      getUsername: () => teacher?.email,
    };

    return buildProblemCanvasOwner({
      problemContext: makeProblemContext(partialProblemContext),
    });
  }, [teacher]);

  const handleRefresh = async (): Promise<void> => {
    commonEvents.getNewSkillSample(type);
    await refresh();
  };

  const handleFullscreenClick = (): void => {
    if (!problemData) return;

    const args = {
      type: `${problemData.subcat}`,
      title,
      description,
      siblingSkills: siblingSkills ?? [],
      studentInClassId,
      studentProblemData: studentInClassId ? problemData : null,
      studentInTestId,
      examRestoreId,
      helpRequestedProblemData,
    };

    openFullscreenWith(args);
  };

  useEffect(() => {
    onHeightChange?.();
  }, [onHeightChange]);

  // Always send a clone since ProblemCanvas mutates
  const problemDataClone = useMemo(() => cloneDeep(problemData), [problemData]);

  if (!problemData) return null;

  return (
    <Box
      data-intercom-target="skill-preview-area"
      data-testid={ids.main}
      sx={{ position: 'relative', width: 1 }}
    >
      <AppBar color="default" elevation={elevation} position="static">
        <Toolbar variant="dense">
          <Tabs
            data-testid={ids.tabs.main}
            onChange={handleTabChange}
            sx={{ [`.${tabClasses.root}`]: { minWidth: 160 } }}
            value={tab}
          >
            <Tab
              data-intercom-target="skill-preview-tabs-problem"
              data-testid={ids.tabs.problem}
              label={
                problemTabText || t(`problemPreview:problemTab.${previewType}`)
              }
              value={SkillPreviewTab.Preview}
            />
            <Tab
              data-intercom-target="skill-preview-tabs-answer"
              data-testid={ids.tabs.answer}
              label={t('problemPreview:answerTab')}
              value={SkillPreviewTab.Answer}
            />
            {missedStudents?.length && (
              <Tab
                data-intercom-target="skill-preview-tabs-missed-students"
                data-testid={ids.tabs.students}
                label={t('problemPreview:studentsTab')}
                value={SkillPreviewTab.Students}
              />
            )}
          </Tabs>
          {!!onAdd && (
            <AddSkill
              disabled={isAddButtonDisabled}
              isLoading={isValidating}
              onAdd={onAdd}
            />
          )}
          <Box sx={{ flex: '1 1 auto' }} />
          {fullscreenFlag && !isPreviewModal && (
            <IconButton onClick={handleFullscreenClick}>
              <Fullscreen />
            </IconButton>
          )}
          {showRefresh && (
            <IconButton
              aria-label={t('problemPreview:buttons.refresh')}
              data-intercom-target="get-new-sample-problem"
              data-testid={ids.refresh}
              disabled={isValidating}
              onClick={handleRefresh}
            >
              <Refresh color="secondary" />
            </IconButton>
          )}
          {!!onClose && (
            <IconButton
              aria-label={t('problemPreview:buttons.close')}
              data-intercom-target="skill-preview-close"
              data-testid={ids.close}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Paper
        elevation={elevation}
        square
        sx={{ height: 1, p: 1, textAlign: 'center' }}
      >
        <ProblemPreviewTabPanel name={SkillPreviewTab.Preview} value={tab}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {problemDataClone && problemDataClone.problem ? (
              <ProblemCanvas
                problemData={{ r: problemDataClone.problem.r }}
                problemCanvasOwner={problemCanvasOwner}
                userId={problemDataClone?.id}
                globalFocus={false}
              />
            ) : null}
          </Box>
        </ProblemPreviewTabPanel>
        <ProblemPreviewTabPanel name={SkillPreviewTab.Answer} value={tab}>
          {problemDataClone && problemDataClone.problem ? (
            <StaticProblemCanvas
              problemData={{ r: problemDataClone.answer.r }}
            />
          ) : null}
        </ProblemPreviewTabPanel>
        {missedStudents?.length && (
          <ProblemPreviewTabPanel name={SkillPreviewTab.Students} value={tab}>
            <Students students={missedStudents} />
          </ProblemPreviewTabPanel>
        )}
        {problemDataClone?.condensed && <CondensedChip />}
        {error && (
          <Typography align="center" variant="h4">
            {t('problemPreview:fetchingProblem.error')}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default ProblemPreview;
