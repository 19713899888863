import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';
import { PropertyPath } from '~/lib/paths';
import { SortOrder } from '~/lib/sortHelper';

import { TableRowData } from '../types';
import { UseSortColumns } from '../useSortColumns';

/**
 * These only change when `onSort` is triggered
 */
interface SortingContext<
  Item extends TableRowData,
  Path extends PropertyPath<Item> = PropertyPath<Item>,
> {
  isSorting: boolean;
  onSort: UseSortColumns<Item, Path>['onSort'];
  sortBy: UseSortColumns<Item, Path>['sortBy'];
  sortOrder: SortOrder;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortingContext = createNamedContext<SortingContext<any, any>>(
  'DataTable.SortingContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const useDataTableSorting = <
  Item extends TableRowData,
>(): SortingContext<Item> => {
  const value = useContext<SortingContext<Item>>(SortingContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      '`useDataTableSorting` must be used from within <DataTable />',
    );
  }

  return value;
};
