import clsx from 'clsx';
import { position, stripUnit } from 'polished';
import { memo, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { styled, Table as UITable, TableProps as UITableProps } from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';

import {
  useDataTableColumns,
  useDataTableSorting,
  useTableId,
} from '../contextHooks';
import { EitherColumn, TableRowData } from '../index';

export const INTERCOM_LOGO_BUFFER = 95;

const ids = allIds.dataTable;

const getScrollParent = (node: Node | null): Element | null => {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) return null;

  const { overflowY } = window.getComputedStyle(node);

  if (
    overflowY !== 'hidden' &&
    overflowY !== 'visible' &&
    node.scrollHeight >= node.clientHeight
  ) {
    return node;
  }

  return (
    getScrollParent(node.parentNode) ||
    document.scrollingElement ||
    document.body
  );
};

export const getColClassName = <Item extends TableRowData>(
  column: EitherColumn<Item>,
): string => {
  if (column.name) return column.name;

  // convert to a obj.path
  const path = Array.isArray(column.prop) ? column.prop.join('.') : column.prop;

  // replace `.` with `-` because otherwise classes need to be like `a\.b`
  return `${path}`.replace(/\./g, '-');
};

const PREFIX = 'DataTableTable';

export const tableClasses = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  column: `${PREFIX}-column`,
  withDivider: `${PREFIX}-withDivider`,
  iconColumn: `${PREFIX}-iconColumn`,
  selectableColumn: `${PREFIX}-selectableColumn`,
};

interface RootProps {
  removeIntercomPadding: boolean | undefined;
}

const Root = styled('div', {
  name: PREFIX,
  shouldForwardProp: prop => prop !== 'removeIntercomPadding',
})<RootProps>(({ removeIntercomPadding, theme }) => ({
  [`&.${tableClasses.root}`]: {
    maxHeight: 'none',
    overflow: 'auto',
    overscrollBehavior: 'none',
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      // The `theme.spacing(1.5)` is enough space to prevent the horizontal
      // scrollbar on Mac from preventing the content from being visible.
      paddingBottom: removeIntercomPadding
        ? theme.spacing(1.5)
        : INTERCOM_LOGO_BUFFER,
    },
  },
  [`& .${tableClasses.table}`]: {
    tableLayout: 'fixed',
    '& th': {
      borderBottom: 0,
      whiteSpace: 'nowrap',
      '&:after': {
        ...position('absolute', 'auto', 0, 0, 0),
        content: '""',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    '& th:first-child, & td:first-child': {
      paddingLeft: theme.spacing(1),
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(1),
    },
  },
  [`& .${tableClasses.withDivider}`]: {
    '& th, & td': {
      borderRight: `1px solid ${theme.palette.grey[500]}`,
    },
  },
  [`& .${tableClasses.iconColumn}`]: { width: theme.typography.pxToRem(48) },
  [`& .${tableClasses.selectableColumn}`]: {
    width: theme.typography.pxToRem(54),
  },
}));

interface TableProps extends UITableProps, Partial<RootProps> {
  children: ReactNode;
  classes?: UITableProps['classes'] &
    Partial<Record<'column' | 'iconColumn' | 'table', string>>;
  hasDivider?: boolean;
  maxHeight?: number;
  /** The minimum width when all columns are visible */
  minWidth?: number;
}

export const Table = memo<TableProps>(function Table<
  Item extends TableRowData,
>({
  children,
  className,
  maxHeight: maxHeightProp,
  minWidth: minWidthProp,
  hasDivider,
  removeIntercomPadding,
  ...tableProps
}: TableProps): JSX.Element {
  const { hiddenColumns, hideableColumns, visibleColumns } =
    useDataTableColumns<Item>();
  const { isSorting } = useDataTableSorting();
  const [maxHeight, setMaxHeight] = useState<number | undefined>(maxHeightProp);
  const tableMinWidth = useMemo(
    () =>
      minWidthProp
        ? minWidthProp -
          hiddenColumns.reduce((sum, id) => {
            const { cellProps, width = 0 } =
              hideableColumns.find(col => col.id === id) || {};
            const columnWidth = cellProps?.style?.width
              ? parseInt(`${stripUnit(cellProps.style.width)}`, 10)
              : width;

            return sum + columnWidth;
          }, 0)
        : undefined,
    [minWidthProp, hiddenColumns, hideableColumns],
  );
  const tableId = useTableId();

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (maxHeightProp) return;
    if (!tableRef.current) return;

    const scrollableParent = getScrollParent(tableRef.current);

    const paddingBottom = scrollableParent
      ? parseInt(window.getComputedStyle(scrollableParent).paddingBottom, 10)
      : 0;
    const calculateMaxHeight = (): void => {
      if (!tableRef.current) return;

      const { top } = tableRef.current.getBoundingClientRect();
      const height = window.innerHeight - top - paddingBottom;

      setMaxHeight(height);
    };

    const observer = new MutationObserver(calculateMaxHeight);

    calculateMaxHeight();

    observer.observe(tableRef.current.parentElement!, {
      attributes: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [maxHeightProp]);

  return (
    <Root
      className={tableClasses.root}
      ref={tableRef}
      removeIntercomPadding={removeIntercomPadding}
      style={maxHeight ? { maxHeight } : {}}
    >
      <UITable
        aria-busy={isSorting ? 'true' : 'false'}
        aria-live="polite"
        className={clsx(
          className,
          tableClasses.table,
          hasDivider && tableClasses.withDivider,
        )}
        data-testid={ids.table(tableId)}
        {...tableProps}
        style={{ ...tableProps.style, minWidth: tableMinWidth }}
      >
        <colgroup>
          {visibleColumns.map(column => (
            <col
              className={clsx(tableClasses.column, getColClassName(column), {
                [tableClasses.iconColumn]: column.isIconOnly,
                [tableClasses.selectableColumn]: column.isSelectColumn,
              })}
              key={column.id}
            />
          ))}
        </colgroup>
        {children}
      </UITable>
    </Root>
  );
});
