import { ComponentType, ReactNode } from 'react';
import { I18nKey } from 'react-i18next';

import { ClassDetail } from '@gmm/sdk/classes';

export type Mutable<T> = { -readonly [P in keyof T]: T[P] };

export interface IndexSignature<T = any> {
  readonly [key: string]: T[keyof T];
}

// Enums
export enum Games {
  bergBuilder = 'BergBuilder',
  desertRace = 'DesertRace',
  invade = 'Invade',
  missileDefense = 'MissileDefense',
  spaceFlappy = 'SpaceFlappy',
  threeSeven = 'ThreeSeven',
}

export enum LoadState {
  Pending,
  Done,
  Error,
}

export enum SnackbarMessage {
  error = 'error',
  success = 'success',
}

export type CurrentClassSettings = Mutable<
  Pick<
    ClassDetail,
    | 'allowedWebGames'
    | 'allowExamsOnMobile'
    | 'allowRaisedHands'
    | 'allowWorkOnAnyProblems'
    | 'autoUnlockExamsProblems'
    | 'code'
    | 'gamesEnabled'
    | 'pointsPerGameCredit'
    | 'subjects'
  >
>;

export enum DisplayNameFormat {
  FirstLast = 'first last',
  LastFirst = 'last first',
}

interface WithLabelKey {
  readonly label?: never;
  readonly labelI18nKey: I18nKey;
}

interface WithLabel<Props = any> {
  readonly label: ReactNode | ComponentType<Props>;
  readonly labelI18nKey?: never;
}

export type WithLabelOrKey<Props = any> = WithLabelKey | WithLabel<Props>;

// Distributes the Pick and Omit across a union instead of only over the intersection
// @see https://github.com/microsoft/TypeScript/issues/28791#issuecomment-443520161
export type AllKeys<T> = T extends T ? keyof T : never;
export type DistributedPick<T, K extends AllKeys<T>> = T extends T
  ? Pick<T, K>
  : never;
export type DistributedOmit<T, K extends AllKeys<T>> = T extends T
  ? Pick<T, Exclude<keyof T, K>>
  : never;

export type RequireOnly<T, K extends keyof T> = Partial<T> &
  Pick<Required<T>, K>;

/**
 * This is the interface for 400 errors in HM
 * @see  https://github.com/frontporch/gmm-hall-monitor/blob/8ba9a4424cfd4c262b9b5b1f8fd18cdeb4b18edb/src/HallMonitor/Controllers/Api/Teachers/TeachersController.cs#L264
 */
export interface ValidationProblemDetails {
  detail: string;
  errors: { [k: string]: string[] };
  errorFormat?: string;
  instance: string;
  status: number;
  title: string;
  violations?: Violation[];
}

export interface Violation {
  field: string;
  message: string;
}

export type Entries<T> = Array<
  Exclude<
    {
      [K in keyof T]: [K, T[K]];
    }[keyof T],
    undefined
  >
>;
