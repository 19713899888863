import { useCallback, useMemo } from 'react';

import { Selectable, useSelectable } from '~/lib/hooks/useSelectable';
import { pathsAreEqual, PropertyPath } from '~/lib/paths';

import { TableRowData } from './types';

export interface Hideable<
  Item,
  P extends PropertyPath<Item> = PropertyPath<Item>,
> {
  readonly id?: string;
  readonly prop: P;
  readonly hideable?: boolean;
}

export interface ToggleColumns<Item, C extends Hideable<Item>> {
  readonly hiddenColumns: Selectable<C['id']>['selectedItems'];
  readonly hideColumn: (column: C) => void;
  readonly hideableColumns: readonly C[];
  readonly isHidden: (column: C) => boolean;
  readonly isVisible: (column: C) => boolean;
  readonly showColumn: (column: C) => void;
  readonly toggleColumnVisibility: (column: C) => void;
  readonly visibleColumns: readonly C[];
}

export const useToggleColumns = <
  Item extends TableRowData,
  C extends Hideable<Item, any> = Hideable<Item>,
>(
  allColumns: readonly C[],
  defaultHiddenColumns: ReadonlyArray<PropertyPath<Item>> = [],
): ToggleColumns<Item, C> => {
  const hideableColumns = useMemo(
    () => allColumns.filter(col => col.hideable),
    [allColumns],
  );
  const isRemovable = useCallback(
    (column: C): boolean => {
      const found = allColumns.find(({ id }) => column.id === id);

      return found ? !!found.hideable : false;
    },
    [allColumns],
  );

  const { isSelected, onSelect, selectedItems } = useSelectable({
    data: hideableColumns.map(col => col.id),
    // Make sure we're not hiding something we shouldn't be able to
    defaultSelected: defaultHiddenColumns?.length
      ? hideableColumns
          .filter(col =>
            defaultHiddenColumns.find(hiddenProp =>
              pathsAreEqual(col.prop, hiddenProp),
            ),
          )
          .map(col => col.id)
      : [],
  });

  const isHidden = useCallback(
    (column: C): boolean => isSelected(column.id),
    [isSelected],
  );

  const isVisible = useCallback(
    (column: C): boolean => !isSelected(column.id),
    [isSelected],
  );

  const hideColumn = useCallback(
    (column: C): void => {
      if (isRemovable(column)) {
        onSelect(column.id, true);
      }
    },
    [onSelect, isRemovable],
  );

  const showColumn = useCallback(
    (column: C): void => onSelect(column.id, false),
    [onSelect],
  );

  const toggleColumnVisibility = useCallback(
    (column: C): void => {
      if (isSelected(column.id)) {
        return showColumn(column);
      }

      hideColumn(column);
    },
    [showColumn, hideColumn, isSelected],
  );

  const visibleColumns = useMemo(
    () => allColumns.filter(isVisible),
    [allColumns, isVisible],
  );

  return {
    hiddenColumns: selectedItems,
    hideColumn,
    hideableColumns,
    isHidden,
    isVisible,
    showColumn,
    toggleColumnVisibility,
    visibleColumns,
  };
};
