import { FC } from 'react';
import { I18nKey, Namespace, useTranslation } from 'react-i18next';

import { Box } from '@gmm/ui';
import { NameFormatMenu } from '~/lib/nameFormatMenu';
import { SortOrder } from '~/lib/sortHelper';

export type NameProps = 'firstName' | 'lastName';

export const createNameSort =
  (isDisplayNameFirstLast: boolean) =>
  (
    sortOrder: SortOrder,
  ): [readonly [NameProps, NameProps], [SortOrder, SortOrder]] => {
    const nameIndicators = isDisplayNameFirstLast
      ? (['firstName', 'lastName'] as const)
      : (['lastName', 'firstName'] as const);

    return [nameIndicators, [sortOrder, sortOrder]];
  };

interface Props {
  header?: boolean;
  label?: I18nKey;
}

export const DisplayNameLabel: FC<Props> = ({
  header,
  label: overrideLabel,
}) => {
  const [t] = useTranslation<Namespace>(['table']);
  const defaultLabel = t('table:labels.displayName');
  const label = overrideLabel ? t(overrideLabel) : defaultLabel;

  if (!header) return <>{label}</>;

  return (
    <Box sx={{ pl: 3, position: 'relative' }}>
      <Box
        sx={{
          bottom: theme => theme.spacing(-0.5),
          height: theme => theme.spacing(3.75),
          left: theme => theme.spacing(-1),
          m: 'auto',
          position: 'absolute',
          top: theme => theme.spacing(-1),
        }}
      >
        <NameFormatMenu />
      </Box>
      {label}
    </Box>
  );
};
