import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';

import { Columns, EitherColumn } from '../columns';
import { TableRowData } from '../types';
import { ToggleColumns } from '../useToggleColumns';

/**
 * These change based on the following prop changes:
 * `columns`
 *
 * They also change when `toggleColumnVisibility` is triggered
 */
interface ColumnContext<
  Item extends TableRowData = TableRowData,
  C extends EitherColumn<Item> = EitherColumn<Item>,
> extends Pick<
    ToggleColumns<Item, C>,
    | 'hiddenColumns'
    | 'hideableColumns'
    | 'isVisible'
    | 'toggleColumnVisibility'
    | 'visibleColumns'
  > {
  groupColumns: null | Columns<Item>;
  isFirstInGroup: (column: EitherColumn<Item>) => boolean;
  numFrozenColumns: number;
  visibleColumnCount: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ColumnContext = createNamedContext<ColumnContext<any, any>>(
  'DataTable.ColumnContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const useDataTableColumns = <
  Item extends TableRowData,
>(): ColumnContext<Item> => {
  const value = useContext<ColumnContext<Item>>(ColumnContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      '`useDataTableColumn` must be used from within <DataTable />',
    );
  }

  return value;
};
