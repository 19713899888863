import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { ReadOnlyOptions } from '~/lib/sdk';
import { augmentSWR, fetchWithRetry, swrKeyFor } from '~/lib/swr';

import { dedupingInterval, PreviewProps } from './utils';

interface CurrentProblemResponse {
  swrResponse: PreviewProps;
  errorState: string | null;
}

interface Config extends ReadOnlyOptions {
  studentInClassId?: string | null;
}

export const useCurrentProblem = ({
  readOnly,
  studentInClassId,
}: Config): CurrentProblemResponse => {
  const { enqueueError } = useSnackbar();
  const swrKey = swrKeyFor(() =>
    studentInClassId ? ['currentProblems.list', studentInClassId] : null,
  );
  const [errorState, setErrorState] = useState<string | undefined>(undefined);
  const [t] = useTranslation(['studentDetail']);

  const handleError = (error: AxiosError): void => {
    if (error?.response?.status === 410) {
      setErrorState('Student session gone');

      return;
    }

    setErrorState('Unable to load problem');
    enqueueError(t('studentDetail:error.currentProblem'));
  };

  const handleSuccess = (): void => {
    setErrorState(undefined);
  };

  // TODO: Create and use CurrentProblemIndexResponse
  const swrResponse = useSWR(swrKey, fetchWithRetry, {
    dedupingInterval,
    onError: handleError,
    onSuccess: handleSuccess,
    shouldRetryOnError: false,
    revalidateIfStale: !readOnly,
    revalidateOnReconnect: !readOnly,
    use: [augmentSWR('problemData', ({ data }) => data)],
  }) as PreviewProps;

  return { swrResponse, errorState } as CurrentProblemResponse;
};
