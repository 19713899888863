import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';

import { TableRowData } from '../types';

/**
 * This changes when `sortedData` changes
 */
type GetSortedDataContext<Item extends TableRowData> = (
  sortedRowIndex: number,
) => Item;

export const GetSortedDataContext = createNamedContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GetSortedDataContext<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>('DataTable.GetSortedDataContext', null as any);

export const useDataTableRow = <
  Item extends TableRowData,
>(): GetSortedDataContext<Item> => {
  const value = useContext<GetSortedDataContext<Item>>(GetSortedDataContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      '`useDataTableGetSortedData` must be used from within <DataTable />',
    );
  }

  return value;
};
