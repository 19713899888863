import clsx from 'clsx';
import { constant, flatten, groupBy, noop } from 'lodash';
import { position } from 'polished';
import { useMemo } from 'react';

import {
  lighten,
  styled,
  TableBody as MUITableBody,
  TableBodyProps as MUITableBodyProps,
} from '@gmm/ui';
import { useChunking } from '~/lib/hooks/useChunking';
import { useMakeDeepMemo } from '~/lib/hooks/useMakeDeepMemo';
import { pathsAreEqual, PropertyPath } from '~/lib/paths';
import { typedMemo } from '~/lib/typedMemo';

import {
  useDataTableColumns,
  useDataTableData,
  useDataTableSortedData,
  useDataTableSorting,
  useIdentifier,
} from '../contextHooks';
import { TableRowData } from '../types';

import { TableRow } from './tableRow';

const CHUNK_SIZE = 20;

const alwaysTrue = constant(true);

const getStripeValue = (
  zebraStripe?: boolean | 'odd' | 'even',
): '' | 'odd' | 'even' => {
  if (!zebraStripe) return '';
  if (zebraStripe === true) return 'odd';

  return zebraStripe;
};

const isRowStriped = (
  stripeValue: '' | 'odd' | 'even',
  rowIndex: number,
): boolean => {
  if (stripeValue === '') return false;

  const isEven = rowIndex % 2 === 0;

  return isEven ? stripeValue === 'even' : stripeValue === 'odd';
};

const groupRows = <Item,>(
  data: Item[],
  groupFn?: (item: Item) => string | number,
  subgroupFn?: (item: Item) => string | number,
): Item[] => {
  if (!groupFn) return data;

  const groups = groupBy(data, groupFn);
  const groupKeys = Object.keys(groups) as Array<keyof typeof groups>;
  const groupedAndSorted = groupKeys
    .sort()
    .map(value => groupRows(groups[value], subgroupFn));

  return flatten(groupedAndSorted);
};

const PREFIX = 'MobiusTableBody';

export const tableBodyClasses = {
  root: `${PREFIX}-root`,
  row: `${PREFIX}-row`,
  rowStriped: `${PREFIX}-rowStriped`,
};

interface RootProps {
  zebraColumn?: boolean;
}

const Root = styled(MUITableBody, {
  name: PREFIX,
  shouldForwardProp: prop => prop !== 'zebraColumn',
})<RootProps>(({ theme, zebraColumn }) => ({
  position: 'relative',
  zIndex: 0,
  [`&.${tableBodyClasses.root}`]: zebraColumn
    ? {
        '& td:nth-child(even)::before': {
          ...position('absolute', 0),
          content: '""',
          background: lighten(theme.palette.primary.light, 0.6),
          zIndex: -1,
        },
      }
    : {},
  [`& .${tableBodyClasses.row}`]: {
    backgroundColor: theme.palette.background.paper,
    [`&.${tableBodyClasses.rowStriped}`]: {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

interface TableBodyProps<Item extends TableRowData>
  extends MUITableBodyProps,
    RootProps {
  canClickRow?: (rowData: Item, rowIndex: number) => boolean;
  chunk?: boolean;
  getRowClasses?: (rowIndex: number) => string;
  /** Used to create groups within rows.
   * Should return values that can be easily sorted, like numbers
   */
  groupBy?: (item: Item) => string | number;
  onRowClick?: (rowData: Item, rowIndex: number) => void;
  onToggleDetailPanel?: (
    rowData: Item,
    open: boolean,
    prop: PropertyPath<Item>,
  ) => void | boolean;
  rowIntercomTarget?: string;
  zebraStripe?: boolean | 'odd' | 'even';
}

export const TableBody = typedMemo(function TableBody<
  Item extends TableRowData,
>(props: TableBodyProps<Item>): JSX.Element {
  const {
    canClickRow = alwaysTrue,
    chunk,
    className,
    classes: _1,
    getRowClasses,
    groupBy: groupByProp,
    onRowClick,
    onToggleDetailPanel = noop,
    rowIntercomTarget,
    zebraColumn,
    zebraStripe,
    ...tableBodyProps
  } = props;
  const stripeValue = getStripeValue(zebraStripe);
  const makeKey = useMakeDeepMemo();
  const getIdentifier = useIdentifier();
  const { visibleColumns } = useDataTableColumns();
  const { sortBy } = useDataTableSorting();
  const { data } = useDataTableData();
  const sortedColumn = useMemo(
    () => visibleColumns.find(({ prop }) => pathsAreEqual(prop, sortBy)),
    [sortBy, visibleColumns],
  );
  const sortedData = useDataTableSortedData<Item>();
  const groupedData = groupRows(
    [...sortedData],
    groupByProp,
    sortedColumn?.groupBy,
  );

  const chunks = useChunking({
    chunkSize: CHUNK_SIZE,
    data: groupedData,
    isChunking: !!chunk,
  });

  return (
    <Root
      {...tableBodyProps}
      className={clsx(className, tableBodyClasses.root)}
      zebraColumn={zebraColumn}
    >
      {chunks.map(rowData => {
        const rowKey = makeKey(getIdentifier(rowData));
        const originalRowIndex = data.findIndex(d => d === rowData);
        const rowIndex = sortedData.findIndex(d => d === rowData);
        // Stripes are 1-indexed, while arrays are 0-indexed
        const isStriped = isRowStriped(stripeValue, rowIndex + 1);

        return (
          <TableRow
            classes={{
              root: clsx(
                getRowClasses?.(originalRowIndex),
                tableBodyClasses.row,
                isStriped && tableBodyClasses.rowStriped,
              ),
            }}
            key={rowKey}
            onRowClick={
              canClickRow(rowData, originalRowIndex) ? onRowClick : undefined
            }
            onToggleDetailPanel={onToggleDetailPanel}
            rowIndex={rowIndex}
            rowIntercomTarget={rowIntercomTarget}
            rowKey={rowKey}
          />
        );
      })}
    </Root>
  );
});
