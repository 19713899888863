import { useField } from 'formik';
import {
  FC,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { isElement } from 'react-is';

import {
  FormControlLabel,
  FormControlLabelProps,
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@gmm/ui';
import { createNamedContext } from '~/lib/createNamedContext';
import { useMergeHandlers } from '~/lib/hooks/useMergeHandlers';
import { DistributedOmit, DistributedPick } from '~/lib/types';

export interface SwitchProps extends MUISwitchProps {
  'data-testid'?: string;
  FormControlLabelProps?: Partial<FormControlLabelProps>;
  label?: ReactNode;
  name: string;
  value?: string | boolean;
}

export const Switch: FC<SwitchProps> = ({
  FormControlLabelProps,
  inputProps,
  'data-testid': testId,
  label,
  ...props
}) => {
  const control = (
    <MUISwitch
      id={testId}
      {...props}
      data-testid={testId}
      inputProps={
        {
          ...inputProps,
          'data-testid': `${testId}-input`,
        } as unknown as InputHTMLAttributes<HTMLInputElement>
      }
    />
  );

  if (
    isElement(label) ||
    typeof label === 'string' ||
    typeof label === 'number'
  ) {
    return (
      <FormControlLabel
        {...FormControlLabelProps}
        control={control}
        label={label}
      />
    );
  }

  return control;
};

type SwitchContext = DistributedPick<
  SwitchProps,
  'checked' | 'name' | 'onBlur' | 'onChange' | 'type' | 'value'
>;

const SwitchContext = createNamedContext<SwitchContext>(
  'SwitchContext',
  null as any,
);

type FormikSwitchProps = DistributedOmit<SwitchProps, keyof SwitchContext>;

const FormikSwitch = memo<FormikSwitchProps>(function FormikSwitch(props) {
  const value = useContext(SwitchContext);

  return <Switch {...props} {...value} />;
});

export const ConnectedSwitch = memo<SwitchProps>(function ConnectedSwitch({
  name,
  onBlur,
  onChange,
  value,
  ...props
}) {
  const [field] = useField(
    useMemo(
      () => ({
        name,
        type: 'checkbox',
        value: value ? `${value}` : undefined,
      }),
      [name, value],
    ),
  );
  const handleBlur = useMergeHandlers({
    primary: onBlur,
    secondary: field.onBlur,
  });
  const handleChange = useMergeHandlers({
    primary: onChange,
    secondary: field.onChange,
  });

  const contextValue = useMemo<SwitchContext>(
    () => ({
      checked: field.checked,
      name: field.name,
      onBlur: handleBlur,
      onChange: handleChange,
      value: field.value || '',
    }),
    [field.checked, field.name, field.value, handleBlur, handleChange],
  );

  return (
    <SwitchContext.Provider value={contextValue}>
      <FormikSwitch {...props} />
    </SwitchContext.Provider>
  );
});
