import { TableRow as MuiTableRow } from '@gmm/ui';
import { PropertyPath } from '~/lib/paths';
import { typedMemo } from '~/lib/typedMemo';

import { useDataTableColumns, useDataTableRow } from '../contextHooks';
import { TableRowData } from '../types';

import { DetailPanel } from './detailPanel';
import { TableCell } from './tableCell';

interface Props<Item extends TableRowData> {
  classes?: Record<'root', string>;
  onRowClick?: (rowData: Item, rowIndex: number) => void;
  onToggleDetailPanel?: (
    rowData: Item,
    open: boolean,
    prop?: PropertyPath<Item>,
  ) => void | boolean;
  rowIndex: number;
  rowIntercomTarget?: string;
  rowKey: string;
}

export const TableRow = typedMemo(function TableRow<Item extends TableRowData>({
  classes,
  onRowClick,
  onToggleDetailPanel,
  rowIndex,
  rowIntercomTarget,
  rowKey,
}: Props<Item>): JSX.Element {
  const getRow = useDataTableRow<Item>();
  const rowData = getRow(rowIndex);
  const { visibleColumns } = useDataTableColumns<Item>();

  return (
    <>
      <MuiTableRow classes={classes} data-intercom-target={rowIntercomTarget}>
        {visibleColumns.map((column, columnIndex) => (
          <TableCell
            column={column}
            columnIndex={columnIndex}
            key={`${rowKey}-${column.id}`}
            onCellClick={onRowClick}
            onToggleDetailPanel={onToggleDetailPanel}
            plainRowData={rowData}
            rowData={rowData}
            rowIndex={rowIndex}
          />
        ))}
      </MuiTableRow>
      <DetailPanel
        data-testid={`row-${rowIndex}-detail`}
        onToggleDetailPanel={onToggleDetailPanel}
        rowData={rowData}
      />
    </>
  );
});
