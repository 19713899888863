import { ReactNode } from 'react';

import { Box } from '@gmm/ui';
import { typedMemo } from '~/lib/typedMemo';

import { useDataTableSelectedRows } from '../contextHooks';
import { TableRowData } from '../types';

interface Props<Item> {
  children: ReactNode | ((props: { selectedRows: Item[] }) => ReactNode);
}

export const ToolbarActions = typedMemo(function ToolbarActions<
  Item extends TableRowData,
>({ children }: Props<Item>): JSX.Element {
  const { selectedRows } = useDataTableSelectedRows<Item>();

  return (
    <>
      <Box sx={{ flex: '1 1 1rem' }} />
      <Box sx={{ alignItems: 'center', display: 'flex', '> *': { ml: 1 } }}>
        {typeof children === 'function'
          ? children({ selectedRows: [...selectedRows] })
          : children}
      </Box>
    </>
  );
});
