import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';

import { TableRowData } from '../types';

/**
 * These change when the following props change:
 * `forceSort`, `onSelectionChange`
 *
 * This will change anytime sorting changes.
 * This is used internally for rendering each row.
 *
 * If you need to get access to a specific row, use `useDataTableGetSortedData`
 */
type SortedDataContext<Item extends TableRowData> = readonly Item[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortedDataContext = createNamedContext<SortedDataContext<any>>(
  'DataTable.SortedDataContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const useDataTableSortedData = <
  Item extends TableRowData,
>(): SortedDataContext<Item> => {
  const value = useContext<SortedDataContext<Item>>(SortedDataContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      '`useDataTableSortedData` must be used from within <DataTable />',
    );
  }

  return value;
};
