import { FC, ReactNode } from 'react';

import { Box, BoxProps } from '@gmm/ui';

interface Props extends Omit<BoxProps, 'sx'> {
  children: ReactNode;
  name: string;
  value: string;
}

export const ProblemPreviewTabPanel: FC<Props> = ({
  children,
  name,
  value,
  ...props
}) => (
  <Box
    aria-labelledby={`problem-preview-tab-${name}`}
    hidden={name !== value}
    id={`problem-preview-tabpanel-${name}`}
    role="tabpanel"
    sx={{ maxHeight: 1, overflowX: 'auto' }}
    {...props}
  >
    {children}
  </Box>
);
