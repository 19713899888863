/* eslint-disable @typescript-eslint/no-explicit-any */
import { nanoid } from 'nanoid';
import { useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';

type ReturnValueOrString<C> = C extends (...args: any) => infer R ? R : string;
type Memoize<C extends undefined | ((...args: any) => any)> = (
  ...values: unknown[]
) => ReturnValueOrString<C>;

const defaultCallback = (): string => nanoid();

/**
 * Returns a memoize function that does a deep equal on all arguments to
 * determine equality. If you do not supply a callback, it defaults to
 * one creating a string using `nanoid` for each unique value
 */
export const useMakeDeepMemo = <
  C extends undefined | ((...args: any) => any) = undefined,
>(
  callback?: C,
): Memoize<C> => {
  const callbackRef = useRef(callback);
  const cache = useRef<Array<[unknown[], ReturnValueOrString<C>]>>([]);
  const makeKey = useRef<Memoize<C>>((...values) => {
    const found = cache.current.find(([key]) => isEqual(key, values));

    if (!found) {
      const id = callbackRef.current?.(...values) ?? defaultCallback();

      cache.current.push([values, id]);

      return id;
    }

    return found[1];
  });

  useEffect(() => {
    if (callback !== callbackRef.current) {
      cache.current = [];
    }

    callbackRef.current = callback;
  }, [callback]);

  return makeKey.current;
};
