import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { get, has } from 'lodash';

import * as apiSdk from '@gmm/sdk';
import * as voodooSdk from '@gmm/sdk-voodoo';

type StringKeyOf<T> = Extract<keyof T, string>;

type GetPath<Path, K extends string> = [Path] extends [never]
  ? `${K}`
  : Path extends string
  ? string extends Path
    ? never
    : `${Path}.${K}`
  : never;

type ConfigMaker<A extends unknown[], R extends AxiosRequestConfig> = (
  ...args: A
) => R;

type ObjectSwrKey<T, Path = never> = T extends Record<string, unknown>
  ? {
      [K in StringKeyOf<T>]: T[K] extends Record<string, unknown>
        ? GetPath<Path, K> extends string
          ? ObjectSwrKey<T[K], GetPath<Path, K>>
          : never
        : T[K] extends ConfigMaker<infer A, infer R>
        ? R['method'] extends 'get' | 'GET'
          ? GetPath<Path, K> extends string
            ? A extends never[]
              ? GetPath<Path, K>
              : [GetPath<Path, K>, ...A]
            : never
          : never
        : never;
    }[StringKeyOf<T>]
  : never;

export type ApiSdkSwrArguments = ObjectSwrKey<typeof apiSdk | typeof voodooSdk>;

// All potential paths (methods off apiSdk)
type ApiSdkPath =
  | Extract<ApiSdkSwrArguments, string>
  | Extract<ApiSdkSwrArguments, unknown[]>[0];

// Find arguments based on just the path
type SwrKeyArgument<P extends ApiSdkPath> =
  | Extract<ApiSdkSwrArguments, P>
  | Extract<ApiSdkSwrArguments, [P, ...unknown[]]>;

export type ApiSdkSwrKey<P extends ApiSdkPath = ApiSdkPath> =
  | SwrKeyArgument<P>
  | (() => SwrKeyArgument<P> | null)
  | null;

// This will type check the input to match apiSdk paths
export const swrKeyFor = <T extends ApiSdkSwrKey>(swrKey: T): T => swrKey;

const getAxiosConfig = (
  path: string,
  args: unknown[],
): AxiosRequestConfig | undefined => {
  if (!path || !has(apiSdk, path)) return;

  const configMaker = get(apiSdk, path);

  if (typeof configMaker !== 'function') return;

  return configMaker(...args);
};

/**
 * This will look up the config in the sdk by dot path
 *
 * key = `'classes.list'`, config = `apiSdk.classes.list()`
 * key = `['classes.show', '4']`), config = `apiSdk.classes.show('4')`
 *
 * This is only typesafe by using `toApiValueKey` when using `useSWR`
 *
 * ```
 * useSWR(toApiKey(['classes.show', classId]));
 * ```
 */
export const sdkFetcher = async (
  path: string,
  ...args: unknown[]
): Promise<unknown> => {
  const axiosConfig = getAxiosConfig(path, args);

  if (!axiosConfig) return undefined;

  return (await axios.request(axiosConfig)).data;
};

export const fetchWithRetry = async (
  path: string,
  ...args: unknown[]
): Promise<unknown> => {
  try {
    return await sdkFetcher(path, ...args);
  } catch (error: unknown) {
    if (!isAxiosError(error)) throw error;
    const axiosError = error as AxiosError;
    const { response } = axiosError;

    if (response?.headers['retry-after']) {
      const retryAfter = Number(response.headers['retry-after']) || 2;

      await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));

      return sdkFetcher(path, ...args);
    }

    throw error;
  }
};

const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined;
};

const getAxiosConfigForVoodoo = (
  path: string,
  args: unknown[],
): AxiosRequestConfig | undefined => {
  if (!path || !has(voodooSdk, path)) return;

  const configMaker = get(voodooSdk, path);

  if (typeof configMaker !== 'function') return;

  return configMaker(...args);
};

export const voodooSdkFetcher = async (
  path: string,
  ...args: unknown[]
): Promise<unknown> => {
  const axiosConfig = getAxiosConfigForVoodoo(path, args);

  if (!axiosConfig) return undefined;

  return (await axios.request(axiosConfig)).data;
};
