import { TableCell, TableRow } from '@gmm/ui';
import { PropertyPath } from '~/lib/paths';
import { typedMemo } from '~/lib/typedMemo';

import { useDataTableColumns, useDataTableDetailPanels } from '../contextHooks';
import { TableRowData } from '../types';

interface Props<Item> {
  'data-testid': string;
  onToggleDetailPanel?: (
    rowData: Item,
    open: boolean,
    prop?: PropertyPath<Item>,
  ) => void | boolean;
  rowData: Item;
}

export const DetailPanel = typedMemo(function DetailPanel<
  Item extends TableRowData,
>({ 'data-testid': testId, onToggleDetailPanel, rowData }: Props<Item>) {
  const { visibleColumnCount: columnCount } = useDataTableColumns<Item>();
  const { isDetailPanelOpen, renderDetail } = useDataTableDetailPanels<Item>();
  const isDetailOpen = isDetailPanelOpen(rowData);

  if (!isDetailOpen) return null;

  return (
    <TableRow data-testid={testId}>
      <TableCell colSpan={columnCount}>
        {renderDetail(rowData, onToggleDetailPanel)}
      </TableCell>
    </TableRow>
  );
});
