import clsx from 'clsx';
import { memo } from 'react';

import { Toolbar as UIToolbar, ToolbarProps } from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';

import { useDataTableSelectedRows, useTableId } from '../contextHooks';
import { TableRowData } from '../types';

const ids = allIds.dataTable;

interface Props extends ToolbarProps {
  selectedClassName?: string;
}

export const Toolbar = memo<Props>(function Toolbar<Item extends TableRowData>({
  className,
  selectedClassName,
  ...toolbarProps
}: Props) {
  const { selectedRows } = useDataTableSelectedRows<Item>();
  const tableId = useTableId();

  return (
    <UIToolbar
      className={clsx(
        className,
        selectedClassName && {
          [selectedClassName]: selectedRows.length > 0,
        },
      )}
      data-testid={ids.toolbar(tableId)}
      {...toolbarProps}
      sx={{
        bgcolor: 'grey.200',
        boxShadow: 1,
        mb: 1,
        minHeight: 48,
        zIndex: 'appBar',
      }}
    />
  );
});
