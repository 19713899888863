import React, { forwardRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { Dialog, Slide, SlideProps } from '@gmm/ui';
import ProblemPreview from '~/lib/problemPreview';
import {
  useCurrentProblem,
  useExampleProblem,
  useExamProblem,
} from '~/lib/problemPreview/hooks';

import { useFullscreenContext } from '../context';

import { FullscreenHeading } from './heading';

const Transition = forwardRef(function Transition(
  props: SlideProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullscreenPreview: FC = () => {
  const {
    currentType,
    description,
    isModalOpen,
    showNextSibling,
    showPreviousSibling,
    title,
    studentInTestId,
    examRestoreId,
    studentInClassId,
    problemTabText,
    helpRequestedProblemData,
  } = useFullscreenContext();
  const currentProblemResponse = useCurrentProblem({ studentInClassId });
  const examProblemResponse = useExamProblem({
    problemId: examRestoreId,
    studentInTestId,
  });
  const sampleProblemResponse = useExampleProblem({ skillType: currentType });
  const [t] = useTranslation(['problemPreview']);

  const requestingHelpProblem = !!helpRequestedProblemData;
  const examRelated = !!studentInTestId;
  const currentProblem = !requestingHelpProblem && !!studentInClassId;
  const sampleProblem =
    !requestingHelpProblem && !examRelated && !currentProblem;

  const previewProps = currentProblem
    ? currentProblemResponse.swrResponse
    : examRelated
    ? examProblemResponse
    : requestingHelpProblem
    ? { mutate, problemData: helpRequestedProblemData, isValidating: false }
    : sampleProblemResponse;

  const showRefresh = sampleProblem;

  const tabText =
    problemTabText ||
    (sampleProblem
      ? t('problemPreview:problemTab.sample')
      : t('problemPreview:problemTab.current'));

  return (
    <Dialog fullScreen open={isModalOpen} TransitionComponent={Transition}>
      <FullscreenHeading
        description={description}
        showNextSibling={showNextSibling}
        showPreviousSibling={showPreviousSibling}
        title={title}
      />
      <ProblemPreview
        {...previewProps}
        elevation={0}
        isPreviewModal
        showRefresh={showRefresh}
        title={title}
        examRestoreId={examRestoreId}
        studentInTestId={studentInTestId}
        studentInClassId={studentInClassId}
        problemTabText={tabText}
      />
    </Dialog>
  );
};
