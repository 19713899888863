import { useContext } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';
import { Selectable } from '~/lib/hooks/useSelectable';

import { TableRowData } from '../types';

/**
 * These change when the following props change:
 * `data`
 *
 * These also change when `onSelectRow` is triggerd
 * or `DataContext.onSelectAllRows` is triggered
 */
interface SelectedRowsContext<Item extends TableRowData> {
  isRowSelected: Selectable<Item>['isSelected'];
  onSelectRow: Selectable<Item>['onSelect'];
  selectedRows: Selectable<Item>['selectedItems'];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectedRowsContext = createNamedContext<SelectedRowsContext<any>>(
  'DataTable.SelectedRowsContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const useDataTableSelectedRows = <
  Item extends TableRowData,
>(): SelectedRowsContext<Item> => {
  const value = useContext<SelectedRowsContext<Item>>(SelectedRowsContext);

  if (value === null && process.env.NODE_ENV === 'development') {
    throw new Error(
      '`useDataTableSelectedRows` must be used from within <DataTable />',
    );
  }

  return value;
};
