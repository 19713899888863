import { noop } from 'lodash';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ids } from '~/lib/constants';
import { Checkbox, CheckboxProps } from '~/lib/forms';

import { useDataTableData, useDataTableSelectedRows } from './contextHooks';
import { TableRowData } from './types';

const { selectCheckBox } = ids.classes.mixedReview;

interface Props
  extends Omit<
    CheckboxProps,
    'checked' | 'color' | 'data-testid' | 'indeterminate' | 'name'
  > {
  'data-testid'?: string;
  name?: string;
}

export const SelectAllCheckbox = <Item extends TableRowData>({
  'data-testid': testId = selectCheckBox.all,
  name = 'select-all-checkbox',
  onChange = noop,
  ...checkboxProps
}: Props): JSX.Element => {
  const [t] = useTranslation(['table']);
  const { data, onSelectAllRows } = useDataTableData();
  const { selectedRows } = useDataTableSelectedRows<Item>();
  const hasSelectedRows = selectedRows.length > 0;
  const allRowsSelected = selectedRows.length === data.length;
  const indeterminate = hasSelectedRows && !allRowsSelected;
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onSelectAllRows();
    onChange(event, event.target.checked);
  };

  return (
    <Checkbox
      {...checkboxProps}
      checked={hasSelectedRows}
      color={indeterminate ? 'default' : 'secondary'}
      data-testid={testId}
      indeterminate={indeterminate}
      inputProps={{
        'aria-label': t('table:labels.selectAll'),
      }}
      name={name}
      onChange={handleChange}
    />
  );
};
