/* eslint-disable react/display-name */
import clsx from 'clsx';
import produce, { Draft, original } from 'immer';
import { isFunction, isString } from 'lodash';
import { Identifier, Order } from 'natural-orderby';
import {
  ComponentProps,
  ComponentType,
  CSSProperties,
  isValidElement,
  JSXElementConstructor,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { I18nKey } from 'react-i18next';

import {
  MobiusTheme,
  styled,
  StyledComponent,
  SxProps,
  TableCellProps,
} from '@gmm/ui';
import { ids } from '~/lib/constants';
import { useMakeDeepMemo } from '~/lib/hooks/useMakeDeepMemo';
import { getByPath, PropertyPath, PropertyPathValue } from '~/lib/paths';

import { ColumnHeader } from '../columnHeader';
import { SortOrder } from '../sortHelper';

import { DisplayNameLabel, NameProps } from './displayNameLabel';
import { SelectAllCheckbox } from './selectAllCheckbox';
import { SelectRowCheckbox } from './selectRowCheckbox';
import { tableClasses, getColClassName } from './table/table';
import { TableRowData } from './types';
import { Hideable } from './useToggleColumns';

export interface CellRenderProps<Item, K extends PropertyPath<Item>> {
  cellData: PropertyPathValue<Item, K>;
  isDetailOpen: boolean;
  openDetail: string | null;
  rowData: Item;
  rowIndex: number;
  prop: K;
}

type Result<T, Args extends unknown[] = []> = T | ((...args: Args) => T);

export type SortReturnValue<Item> = [
  Identifier<Item> | Array<Identifier<Item>>,
  Order<Item> | Array<Order<Item>>,
];

export interface Column<Item, K extends PropertyPath<Item> = any>
  extends Omit<Hideable<Item, K>, 'id'> {
  alwaysCollapseIfOpen?: boolean;
  canRenderDetailPanel?: Result<boolean, [Item]>;
  /**
   * props that will be spread to the cell (both in the header and the body)
   */
  cellProps?: Omit<TableCellProps, 'classes' | 'id' | 'width'>;
  classes?: Result<Partial<TableCellProps['classes']>, [Item?]>;
  columns?: Columns<Item>;
  detailPanelProp?: Result<string, [Item]>;
  /** Used to create groups within rows.
   * Should return values that can be easily sorted, like numbers
   */
  groupBy?: (item: Item) => string | number;
  headerCellProps?: Omit<TableCellProps, 'classes' | 'id' | 'width'>;
  id?: string;
  inactive?: boolean;
  /** Used by defineDisplayNameColumn to designate it as such */
  isDisplayName?: never;
  isIconOnly?: boolean;
  isSelectColumn?: boolean;
  label:
    | Exclude<ReactNode, string>
    | ComponentType<{ header: boolean }>
    | I18nKey
    | '';
  makeTestId?: (props: {
    columnIndex: number;
    rowIndex: number;
    prop: K;
  }) => string;
  name?: string;
  render?: (props: CellRenderProps<Item, K>) => ReactNode;
  sort?: SortReturnValue<Item> | (() => SortReturnValue<Item>);
  sortable?: Result<boolean>;
  width?: number;
}

interface DisplayNameColumn<Item, K extends PropertyPath<Item> = any>
  extends Omit<Column<Item, K>, 'isDisplayName' | 'sort'> {
  isDisplayName: true;
  sort?: (
    nameIdentifiers: readonly [NameProps, NameProps],
    nameOrders: [SortOrder, SortOrder],
  ) => SortReturnValue<Item>;
}

export type EitherColumn<Item, K extends PropertyPath<Item> = any> =
  | Column<Item, K>
  | DisplayNameColumn<Item, K>;

export type Columns<Item> = ReadonlyArray<EitherColumn<Item>>;

type ColumnDefinition<Item, Prop extends PropertyPath<Item>> = Omit<
  Column<Item, Prop>,
  'isDisplayName' | 'prop'
>;

export const defineColumn = <Item, Prop extends PropertyPath<Item>>(
  prop: Prop,
  {
    canRenderDetailPanel,
    detailPanelProp,
    label,
    sort = [
      (item: Item): PropertyPathValue<Item, PropertyPath<Item>> =>
        getByPath(item, prop),
      'asc',
    ],
    ...definition
  }: ColumnDefinition<Item, Prop>,
): Column<Item, Prop> => ({
  ...definition,
  canRenderDetailPanel: canRenderDetailPanel ?? !!detailPanelProp,
  detailPanelProp,
  label:
    isString(label) && label !== ''
      ? ({ header }) => <ColumnHeader header={header} i18nKey={label} />
      : label,
  prop,
  sort,
});

/**
 * Used to easily create a column for names. Will display the format menu.
 * This automatically adds the `fs-block` class to `classes.root`
 */
export const defineDisplayNameColumn = <Item, Prop extends PropertyPath<Item>>(
  prop: Prop,
  {
    canRenderDetailPanel,
    classes: classesProp,
    detailPanelProp,
    ...definition
  }: Omit<
    DisplayNameColumn<Item, Prop>,
    'isDisplayName' | 'isSelectColumn' | 'label' | 'prop'
  >,
): DisplayNameColumn<Item, Prop> => ({
  ...definition,
  canRenderDetailPanel: canRenderDetailPanel ?? !!detailPanelProp,
  classes: rowData => {
    const classes = isFunction(classesProp)
      ? classesProp(rowData)
      : classesProp;

    return { ...classes, root: clsx('fs-block', classes?.root) };
  },
  detailPanelProp,
  isDisplayName: true,
  label: ({ header }) => <DisplayNameLabel header={header} />,
  prop,
});

export const defineSelectableColumn = <Item, Prop extends PropertyPath<Item>>(
  prop: Prop,
  definition?: Omit<
    ColumnDefinition<Item, Prop>,
    'isIconOnly' | 'isSelectColumn' | 'label' | 'removable' | 'render'
  >,
): Column<Item, Prop> =>
  defineColumn(prop, {
    ...definition,
    label: () => <SelectAllCheckbox />,
    name: ids.dataTable.selectCheckBoxes,
    render: ({ rowIndex }) => <SelectRowCheckbox rowIndex={rowIndex} />,
    isSelectColumn: true,
  });

interface Args {
  header?: boolean;
  label?: ReactNode | ComponentType<{ header?: boolean }>;
}

export const getColumnLabel = ({ header, label }: Args): ReactNode => {
  if (isValidElement(label) || typeof label !== 'function') {
    return label;
  }

  const Label = label as ComponentType<{ header?: boolean }>;

  return <Label header={header} />;
};

export const useColumns = <Item,>(columns: Columns<Item>): Columns<Item> => {
  const makeId = useMakeDeepMemo();
  const addAccessor = useCallback(
    (draft: Draft<Columns<Item>>): void => {
      draft.forEach((column, index) => {
        if (!column.id) column.id = makeId(original(column)?.prop, index);

        if (column.columns) {
          column.columns = produce(original(column)?.columns, addAccessor);
        }
      });
    },
    [makeId],
  );

  return useMemo(() => produce(columns, addAccessor), [addAccessor, columns]);
};

const VALID_COL_CSS_PROP_PREFIXES: Array<keyof CSSProperties> = [
  'background',
  'border',
  'visibility',
  'width',
];

const addSelector = <T extends TableRowData>(
  columnStyles: Record<string, unknown>,
  column: EitherColumn<T>,
): Record<string, unknown> => {
  const style = column.cellProps?.style;

  if (column.width || style) {
    if (style && process.env.NODE_ENV === 'development') {
      const cssProps = Object.keys(style);
      const badProps = cssProps.filter(
        prop =>
          !VALID_COL_CSS_PROP_PREFIXES.some(
            prefix => prop.indexOf(prefix) === 0,
          ),
      );

      if (badProps.length) {
        console.warn(
          `Invalid css props passed for a column: ${badProps.join(', ')}`,
          '\n',
          `Should only be short- or long-handed versions of : ${VALID_COL_CSS_PROP_PREFIXES.join(
            ', ',
          )}`,
        );
      }
    }

    columnStyles[`& .${tableClasses.column}.${getColClassName(column)}`] =
      style ?? {
        width: column.width,
      };
  }

  return column.columns?.reduce(addSelector, columnStyles) || columnStyles;
};

export const withColumnStyles = <T extends TableRowData>(
  columns: ReadonlyArray<EitherColumn<T>>,
): (<C extends JSXElementConstructor<ComponentProps<C>>>(
  component: C,
) => StyledComponent<
  ComponentProps<C> & {
    theme?: MobiusTheme;
    as?: React.ElementType;
    sx?: SxProps<MobiusTheme>;
  },
  {},
  {}
>) => {
  const columnStyles = columns.reduce(
    addSelector,
    {} as Record<string, unknown>,
  );

  return (component: any) => styled(component)(columnStyles);
};
