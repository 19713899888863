import clsx from 'clsx';
import { isFunction } from 'lodash';
import { memo, ReactNode } from 'react';

import {
  styled,
  TableCell,
  TableHead,
  TableHeadProps,
  TableRow,
  TableSortLabel,
  tableCellClasses,
} from '@gmm/ui';
import { ids } from '~/lib/constants';
import { pathsAreEqual } from '~/lib/paths';

import { EitherColumn, getColumnLabel } from '../columns';
import { useDataTableColumns, useDataTableSorting } from '../contextHooks';
import { TableRowData } from '../types';

const PREFIX = 'DataTableHeader';
const classes = {
  frozen: `${PREFIX}-frozen`,
  isIconOnly: `${PREFIX}-isIconOnly`,
  hasBorder: `${PREFIX}-hasBorder`,
  sticky: `${PREFIX}-sticky`,
  group: `${PREFIX}-group`,
};

const Root = styled(TableHead, { name: PREFIX })(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  [`& .${classes.group}`]: {
    [`& + $heading th.${classes.sticky}, & + $heading th.${classes.frozen}`]: {
      top: theme.spacing(4.5),
    },
  },
  [`& .${classes.frozen}`]: {
    background: theme.palette.background.paper,
    left: 0,
    position: 'sticky',
    top: 0,
    zIndex: 12,
    '&::before': {
      backgroundColor: theme.palette.grey[500],
      bottom: 0,
      content: '""',
      position: 'absolute',
      right: -1,
      top: 0,
      width: 1,
    },
  },
  [`& .${classes.isIconOnly}`]: {
    '& > button': {
      cursor: 'pointer',
      transition: `${theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.complex,
      })}, ${theme.transitions.create('background-color', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shortest,
      })}`,
    },
  },
  [`& .${classes.hasBorder}`]: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
  },
  [`& .${tableCellClasses.sizeSmall}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [`& .${classes.sticky}`]: {
    background: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
}));

interface Props extends TableHeadProps {
  classes?: Partial<Record<'group' | 'root' | 'row', string>>;
}

export const TableHeader = memo<Props>(function TableHeader<
  Item extends TableRowData,
>(props: Props): JSX.Element {
  const { children, ...tableHeadProps } = props;
  const { groupColumns, isFirstInGroup, numFrozenColumns, visibleColumns } =
    useDataTableColumns<Item>();
  const { sortBy, sortOrder, onSort } = useDataTableSorting<Item>();

  const renderCell = (
    column: EitherColumn<Item>,
    columnIndex: number,
    group = false,
  ): ReactNode => {
    const {
      cellProps,
      columns,
      headerCellProps,
      isIconOnly,
      label,
      prop,
      sortable,
    } = column;
    const isSortable = isFunction(sortable) ? sortable() : sortable;
    const isSorted = isSortable && pathsAreEqual(prop, sortBy);
    const renderContent = (content: ReactNode): ReactNode => {
      if (group && !columns?.length) return '';

      if (group || !isSortable) {
        return content;
      }

      const onClick = (): void => onSort(prop);

      return (
        <TableSortLabel
          data-testid={ids.dataTable.column(prop)}
          active={isSorted}
          direction={sortOrder}
          onClick={onClick}
        >
          {content}
        </TableSortLabel>
      );
    };

    const isFrozen = columnIndex < numFrozenColumns;

    return (
      <TableCell
        {...cellProps}
        {...headerCellProps}
        className={clsx(cellProps?.className, {
          [classes.sticky]: !isFrozen,
          [classes.frozen]: isFrozen,
          [classes.hasBorder]: isFirstInGroup(column),
          [classes.isIconOnly]: isIconOnly,
        })}
        colSpan={columns?.length ?? undefined}
        key={`header-${columnIndex}`}
        sortDirection={isSorted && sortOrder}
      >
        {renderContent(
          getColumnLabel({
            header: true,
            label,
          }),
        )}
      </TableCell>
    );
  };

  return (
    <Root {...tableHeadProps}>
      {groupColumns && (
        <TableRow classes={{ root: classes.group }}>
          {groupColumns
            .filter(
              column =>
                column.columns?.some(col => visibleColumns.includes(col)) ||
                visibleColumns.includes(column),
            )
            .map((column, index) => renderCell(column, index, true))}
        </TableRow>
      )}
      <TableRow>
        {visibleColumns.map((column, index) => renderCell(column, index))}
      </TableRow>
      {children}
    </Root>
  );
});
